import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { IoArrowBackSharp } from "react-icons/io5";

const Gratuity = () => {
  const [value, setValue] = useState({
    year: 5,
    salary: 10000,
    act: "yes",
  });
  const [result, setResult] = useState({
    invested: 0,
    total: 0,
  });
  const [visible, setVisible] = useState({
    interest: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "salary") {
      if (/^\d*$/.test(value)) {
        setValue((prevdata) => ({
          ...prevdata,
          [name]: value,
        }));
      }
    } else {
      setValue((prevdata) => ({
        ...prevdata,
        [name]: value,
      }));
    }
  };
  const check = () => {
    if (Number(value.interest) > 24 || Number(value.interest) < 4) {
      setVisible((prevdata) => ({
        ...prevdata,
        interest: true,
      }));
    } else {
      setVisible((prevdata) => ({
        ...prevdata,
        interest: false,
      }));
    }
  };

  useEffect(() => {
    calculate();
  }, []);

  useEffect(() => {
    calculate();
  }, [value]);

  const calculate = () => {
    check();
    let gratuity;
    if (value.act === "yes") {
      gratuity = (Number(value.salary) * Number(value.year) * 15) / 26;
    } else {
      gratuity = (Number(value.salary) * Number(value.year) * 15) / 30;
    }
    setResult(gratuity);
  };
  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="d-flex align-items-center mb-5 gap-4">
          <IoArrowBackSharp
            onClick={goBack}
            style={{ fontSize: "1.5rem", cursor: "pointer" }}
          />
          <h1>Gratuity Calculator</h1>
        </div>
        <Row className="align-items-center">
          <Col className="col-">
            <Form>
              <Form.Group className="mb-4">
                <Form.Label>Salary (Basic Pay + D.A) Optional</Form.Label>
                <Form.Control
                  type="text"
                  name="salary"
                  value={value.salary}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>
                  No. of Years of Service (Min: 5 Years, Max: 50 Years)
                </Form.Label>
                <Form.Control
                  type="text"
                  name="year"
                  value={value.year + "Years"}
                  readOnly
                />
                <Row className="align-items-center">
                  <Col xs="auto">
                    <Form.Label>5 Year</Form.Label>
                  </Col>
                  <Col>
                    <Form.Range
                      min={5}
                      max={50}
                      value={value.year}
                      name="year"
                      onChange={handleChange}
                    />
                  </Col>
                  <Col xs="auto">
                    <Form.Label>30 Year</Form.Label>
                  </Col>
                </Row>
                {visible.age && (
                  <Form.Text className="text-danger">
                    Error: Please enter rate between 0 % and 20 %
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group>
                <Form.Label>Employees covered under the Act</Form.Label>
                <div className="d-flex gap-4">
                  <Form.Check
                    type="radio"
                    name="act"
                    value="yes"
                    label="Yes"
                    checked={value.act === "yes"}
                    onChange={handleChange}
                  />
                  <Form.Check
                    type="radio"
                    name="act"
                    value="no"
                    label="No"
                    checked={value.act === "no"}
                    onChange={handleChange}
                  />
                </div>
              </Form.Group>
            </Form>
          </Col>
          <Col className=" d-flex justify-content-center">
            <div className="p-5 border rounded border-warning shadow text-center">
              <p className="mb-0" style={{ color: "gray", fontSize: "1.2rem" }}>
                Total EMI Payable
              </p>{" "}
              <h2 className="mb-3">
                <strong>
                  {"₹ "}
                  {result.toLocaleString("en-IN", { maximumFractionDigits: 2 })}
                </strong>
              </h2>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Gratuity;

import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { IoArrowBackSharp } from "react-icons/io5";

const Salary = () => {
  const [value, setValue] = useState({
    ctc: 100000,
    ptax: 0,
    bonusType: "percentage",
    bonusPer: 0,
    bonusAmount: 0,
    employerPF: 0,
    employeePF: 0,
    insaurance: 0,
  });
  const [result, setResult] = useState({
    gross: 0,
    bonus: 0,
    mded: 0,
    yded: 0,
    mSalary: 0,
    ySalary: 0,
  });
  const [visible, setVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "bonusType") {
      setValue((prevdata) => ({
        ...prevdata,
        [name]: value,
      }));
    } else {
      if (/^\d*$/.test(value)) {
        setValue((prevdata) => ({
          ...prevdata,
          [name]: value,
        }));
      }
    }
  };
  const check = () => {
    if (Number(value.presentAge) > Number(value.retireAge)) {
      setVisible((prevdata) => ({
        ...prevdata,
        age: true,
      }));
    } else {
      setVisible((prevdata) => ({
        ...prevdata,
        age: false,
      }));
    }
  };



  const calculate = () => {
    if (value.bonusType === "percentage") {
      let bonusPerYear = (Number(value.ctc) * Number(value.bonusPer)) / 100;
      let ctcAmountFinal = Number(value.ctc) - bonusPerYear;
      let monthyDeduc =
        Number(value.ptax) +
        Number(value.employerPF) +
        Number(value.employeePF) +
        Number(value.insaurance);
      let yearlyDeduc = monthyDeduc * 12;
      let ctcAmountYear = ctcAmountFinal - yearlyDeduc;
      let ctcAmountMon = ctcAmountYear / 12;

      setResult({
        gross: ctcAmountFinal,
        bonus: bonusPerYear,
        mded: monthyDeduc,
        yded: yearlyDeduc,
        mSalary: ctcAmountMon,
        ySalary: ctcAmountYear,
      });
    } else {
      let bonusPerYear = Number(value.bonusAmount);
      let ctcAmountFinal = Number(value.ctc) - bonusPerYear;
      let monthyDeduc =
        Number(value.ptax) +
        Number(value.employerPF) +
        Number(value.employeePF) +
        Number(value.insaurance);
      let yearlyDeduc = monthyDeduc * 12;
      let ctcAmountYear = ctcAmountFinal - yearlyDeduc;
      let ctcAmountMon = ctcAmountYear / 12;

      setResult({
        gross: ctcAmountFinal,
        bonus: bonusPerYear,
        mded: monthyDeduc,
        yded: yearlyDeduc,
        mSalary: ctcAmountMon,
        ySalary: ctcAmountYear,
      });
    }
  };
  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="page-wrapper">
      <div className="content">
      <div className="d-flex align-items-center mb-5 gap-4">
      <IoArrowBackSharp onClick={goBack} style={{fontSize:"1.5rem",cursor:"pointer"}} />
        <h1>Salary Calculator</h1>
        </div>
        <Row className="align-items-center">
          <Col className="col-">
            <Row>
              <Col>
                <Form>
                  <Form.Group className="mb-4">
                    <Form.Label>Cost to Company (CTC)</Form.Label>
                    <Form.Control
                      type="text"
                      name="ctc"
                      onChange={handleChange}
                      value={value.ctc}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label>Professional tax ( Optional )</Form.Label>
                    <Form.Control
                      type="text"
                      name="ptax"
                      onChange={handleChange}
                      value={value.ptax}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label>Employer PF ( Optional )</Form.Label>
                    <Form.Control
                      type="text"
                      name="employerPF"
                      onChange={handleChange}
                      value={value.employerPF}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label>Employee Insurance ( Optional )</Form.Label>
                    <Form.Control
                      type="text"
                      name="insaurance"
                      onChange={handleChange}
                      value={value.insaurance}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col>
                <Form>
                  <Form.Group className="mb-4">
                    <Form.Label>Bonus included in CTC as</Form.Label>
                    <Form.Select
                      type="text"
                      name="bonusType"
                      onChange={handleChange}
                      value={value.bonusType}
                    >
                      <option value="percentage">Percentage</option>
                      <option value="amount">Amount</option>
                    </Form.Select>
                  </Form.Group>
                  {value.bonusType === "percentage" ? (
                    <Form.Group className="mb-4">
                      <Form.Label>Bonus Percentage</Form.Label>
                      <Form.Control
                        type="text"
                        name="bonusPer"
                        onChange={handleChange}
                        value={value.bonusPer}
                      />
                    </Form.Group>
                  ) : (
                    <Form.Group className="mb-4">
                      <Form.Label>Bonus Amount</Form.Label>
                      <Form.Control
                        type="text"
                        name="bonusAmount"
                        onChange={handleChange}
                        value={value.bonusAmount}
                      />
                    </Form.Group>
                  )}
                  <Form.Group className="mb-4">
                    <Form.Label>Employee PF ( Optional )</Form.Label>
                    <Form.Control
                      type="text"
                      name="employeePF"
                      onChange={handleChange}
                      value={value.employeePF}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Button className="w-100" onClick={calculate}>
              {" "}
              Calculate{" "}
            </Button>
          </Col>
          <Col className=" d-flex justify-content-center">
            <div className="p-5 border rounded border-warning shadow">
              <p className="mb-0" style={{ color: "gray", fontSize: "1.4rem" }}>
                Total Gross Pay
              </p>{" "}
              <h2 className="mb-3">
                <strong>
                  {" "}
                  ₹{" "}
                  {result.gross.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                  })}{" "}
                </strong>
              </h2>
              <p className="mb-0" style={{ color: "gray", fontSize: "1.2rem" }}>
                Yearly Performance Bonus
              </p>{" "}
              <h3 className="mb-3">
                <strong>
                  {" "}
                  ₹{" "}
                  {result.bonus.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                  })}{" "}
                </strong>
              </h3>
              <p className="mb-0" style={{ color: "gray", fontSize: "1.2rem" }}>
              Total Monthly Deductions
              </p>{" "}
              <h3 className="mb-3">
                <strong>
                  {" "}
                  ₹{" "}
                  {result.mded.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                  })}{" "}
                </strong>
              </h3>
              <p className="mb-0" style={{ color: "gray", fontSize: "1.2rem" }}>
              Total Yearly Deductions
              </p>{" "}
              <h3 className="mb-3">
                <strong>
                  {" "}
                  ₹{" "}
                  {result.yded.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                  })}{" "}
                </strong>
              </h3>
              <p className="mb-0" style={{ color: "gray", fontSize: "1.2rem" }}>
              Take Home Monthly Salary
              </p>{" "}
              <h3 className="mb-3">
                <strong>
                  {" "}
                  ₹{" "}
                  {result.mSalary.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                  })}{" "}
                </strong>
              </h3>
              <p className="mb-0" style={{ color: "gray", fontSize: "1.2rem" }}>
              Take Home Yearly Salary
              </p>{" "}
              <h3 className="mb-3">
                <strong>
                  {" "}
                  ₹{" "}
                  {result.ySalary.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                  })}{" "}
                </strong>
              </h3>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Salary;

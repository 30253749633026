import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Layout from './Layout';
import { AuthContext } from '../Context/AuthContext';


const PrivateLayout = () => {
  const token = localStorage.getItem('token');
//   const token = localStorage.getItem('token');

  if (!token ) {
    
    return <Navigate to="/login" />;
  }
  else{
  return <Layout><Outlet /></Layout>;}
};

export default PrivateLayout;
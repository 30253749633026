import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import config from "../../Config";
import { AuthContext } from "./AuthContext";
import { toast } from "react-toastify";

const ItemContext = createContext();

const ItemProvider = ({ children }) => {
  const token = localStorage.getItem("token");
  const { logout } = useContext(AuthContext);
  const [items, setItems] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [level, setLevel] = useState([]);
  const getItems = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/getalls`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        setItems(res.data.data);
        // console.log("Items",res.data)
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
      }
    }
  };

  const createItem = async (data) => {
    try {
      const res = await axios.post(`${config.BASE_URL}/item`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        toast.success("Item Created Succesfully!");
        getItems();
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  const updateItem = async (id, data) => {
    try {
      const res = await axios.put(
        `${config.BASE_URL}/update-item/${id}`,
        data,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (res.status === 200) {
        toast.success("Item updated Succesfully!");
        getItems();
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  const deleteItem = async (id) => {
    try {
      const res = await axios.delete(`${config.BASE_URL}/delete-item/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        toast.success("Item deleted Succesfully!");
        getItems();
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  const getNotifications = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/notifications`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        setNotifications(res.data.data);
        // console.log("Items",res.data)
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
      }
    }
  };

  useEffect(() => {
    getItems();
    getNotifications();
  }, []);
  return (
    <ItemContext.Provider
      value={{ getItems, items, createItem, updateItem, deleteItem, notifications,getNotifications }}
    >
      {children}
    </ItemContext.Provider>
  );
};

export { ItemProvider, ItemContext };

import React, { useContext, useEffect, useState } from "react";
import { VendorContext } from "../Context/VendorContext";
import { PurchaseContext } from "../Context/PurchaseContext";
import axios from "axios";
import config from "../../Config";
import { AuthContext } from "../Context/AuthContext";
import { toast } from "react-toastify";
import Select from 'react-select';

const AddPayment = () => {
  const { vendors } = useContext(VendorContext);
  const { purchases } = useContext(PurchaseContext);
  const {logout} = useContext(AuthContext);
  const [purchaseList, setPurchaseList] = useState([]);
  const token = localStorage.getItem('token');
  const [paymentData, setPaymentData] = useState({
    vendorId: "",
    purchaseOrder: "",
    paymentDate: "",
    amount: 0,
    paymentType: "",
  });

  const filterPurchase = () => {
    setPurchaseList(
      purchases.filter((element) => element.vendorId._id === paymentData.vendorId)
    );
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name === 'purchaseOrder'){
        const valueObj = JSON.parse(value);
      setPaymentData((prevdata) => ({
        ...prevdata,
        [name]: valueObj._id,
        totalPrice:valueObj.totalPrice
      }));
    }
    else {

      setPaymentData((prevdata) => ({
        ...prevdata,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    filterPurchase();
    setPaymentData((prevdata) => ({
      ...prevdata,
      purchaseOrder: "",
    }));
  }, [paymentData.vendorId]);

  const handleSubmit = async () => {
    try {
      if (
        paymentData.amount > 0 &&
        paymentData.paymentDate !== "" &&
        paymentData.paymentType !== "" &&
        paymentData.vendorId !== "" &&
        paymentData.purchaseOrder !== ""
      ) {
       
        const res = await axios.post(
          `${config.BASE_URL}/add-payment`,
          paymentData,{
            headers: {'Authorization': `Bearer ${token}`},
          }
        );
        if (res.status === 200) {
          toast.success("Payment Created succesfully!");
          setPaymentData({
            vendorId: "",
            purchaseOrder: "",
            paymentDate: "",
            amount: 0,
            paymentType: "",
          });
        }
      }
      else{
        toast.warning("Please enter All Fields");
      }
    } catch (error) {
      if(error.response.status === 401) {logout(); toast.error("Your token has expired please login again!");}
    }
  };

  const handleSelectChange = (selectedOption, name) => {
      setPaymentData((prevdata) => ({
        ...prevdata,
        [name]: selectedOption.value,
      }));
    };

  const vendorOptions = vendors.map((element) => ({
    value: element._id,
    label: element.vendorsname,
  }));


  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Add Payment</h4>
            <h6>Add/Update Payments</h6>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6 col-sm-6 col-12">
                <div className="form-group">
                  <label>Vendor</label>
                  <Select options={vendorOptions}  onChange={(selectedOption)=>handleSelectChange(selectedOption,'vendorId')}/>
                  {/* <select
                    className="select"
                    value={paymentData.vendor}
                    onChange={handleChange}
                    name="vendor"
                  >
                    <option disabled value={""}>
                      Choose Vendor
                    </option>
                    {vendors &&
                      vendors.map((element) => (
                        <option key={element._id} value={element._id}>
                          {" "}
                          {element.vendorsname}{" "}
                        </option>
                      ))}
                  </select> */}
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-12">
                <div className="form-group">
                  <label>Purchase Order</label>
                  <select
                    className="select"
                    name="purchaseOrder"
                    value={paymentData.purchaseOrder}
                    onChange={handleChange}
                  >
                    <option disabled value={""}>
                      Choose Purchase
                    </option>
                    {purchases &&
                      purchaseList.map((element) => (
                        <option
                          key={element._id}
                          value={JSON.stringify(element)}
                        >{` ${element._id} || Grand Total: ${element.totalPrice} /- `}</option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-12">
                <div className="form-group">
                  <label>Payment Date</label>
                  <div className="input-groupicon">
                    <input
                      type="date"
                      name="paymentDate"
                      value={paymentData.paymentDate}
                      onChange={handleChange}
                      placeholder="Choose Date"
                      className="datetimepicker"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-12">
                <div className="form-group">
                  <label>Amount</label>
                  <div className="input-groupicon">
                    <input
                      type="number"
                      name="amount"
                      value={paymentData.amount}
                      onChange={handleChange}
                      placeholder="Enter Amount"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-12">
                <div className="form-group">
                  <label>Payment Type</label>
                  <select
                    className="select"
                    value={paymentData.paymentType}
                    onChange={handleChange}
                    name="paymentType"
                  >
                    <option value={""} disabled>
                      Select Payment Type
                    </option>
                    <option value={"cash"}>Cash</option>
                    <option value={"upi"}>UPI</option>
                  </select>
                </div>
              </div>

              <div className="col-lg-12 d-flex justify-content-end">
                <button type="button" onClick={handleSubmit} className="btn btn-submit me-2">
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() =>
                    setPaymentData({
                      vendor: "",
                      purchaseOrder: "",
                      paymentDate: "",
                      amount: 0,
                      paymentType: "",
                    })
                  }
                  className="btn btn-cancel"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPayment;

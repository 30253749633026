import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import config from "../../Config";
import axios from "axios";
import DataTable from "react-data-table-component";
import { AuthContext } from "../Context/AuthContext";
import { toast } from "react-toastify";

const UserList = () => {
  const { fetchRoles, role, permission, logout } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState([]);
  const [editShow, setEditShow] = useState(false);
  const token = localStorage.getItem("token");

  const [editFormData, setEditFormData] = useState(null);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    mobile: "",
    role: "",
    address: "",
    profilePicture: null,
  });

  const tableHead = [
    {
      name: "SNo",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "70px",
    },
    {
      name: "Profile Image",
      selector: (row) => (
        <div className="product-img">
          {row.profilePicture !== "" ? (
            <img
              src={`${config.IMG_URL}/${row.profilePicture}`}
              alt="UserImage"
              style={{ height: "60px", width: "60px", borderRadius: "30px" }}
            />
          ) : (
            <img
              src="/img/profile.png"
              alt="UserImage"
              style={{ height: "60px", width: "60px", borderRadius: "30px" }}
            />
          )}
        </div>
      ),
      sortable: false,
    },
    {
      name: "User Name",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.role,
      sortable: true,
    },
    {
      name: "Phone No.",
      selector: (row) => row.mobile,
      sortable: true,
    },
    ...(permission.user.edit || permission.user.delete
      ? [
          {
            name: "Action",
            selector: (row) => (
              <>
                {permission.user.edit && (
                  <a onClick={() => handleEditClick(row)} className="me-3">
                    <img src="/img/icons/edit.svg" alt="img" />
                  </a>
                )}
                {permission.user.delete && (
                  <a
                    onClick={() => handleDelete(row._id)}
                    className="confirm-text"
                  >
                    <img src="/img/icons/delete.svg" alt="img" />
                  </a>
                )}
              </>
            ),
            sortable: false,
          },
        ]
      : []),
  ];

  const fetchUsers = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/users-get`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        setUsers(res.data.data);
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  const handleDelete = async (id) => {
    const isConfirm = window.confirm(
      "Are you sure, you want to delete the item?"
    );
    if (isConfirm) {
      try {
        const res = await axios.delete(
          `${config.BASE_URL}/users-delete/${id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (res.status === 200) {
          toast.success("User Deleted");
          fetchUsers();
        }
      } catch (error) {
        if (error.response.status === 401) {
          logout();
          toast.error("Your token has expired please login again!");
        }
      }
    }
  };

  const handleEditClick = (data) => {
    setFormData(data);
    setEditShow(true);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "profilePicture") {
      const file = files[0];
      setFormData((prevdata) => ({
        ...prevdata,
        [name]: file,
      }));
    } else {
      setFormData((prevdata) => ({
        ...prevdata,
        [name]: value,
      }));
    }
  };
  const handleEditChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "profilePicture") {
      const file = files[0];
      setEditFormData((prevdata) => ({
        ...prevdata,
        [name]: file,
      }));
    } else {
      setEditFormData((prevdata) => ({
        ...prevdata,
        [name]: value,
      }));
      setFormData((prevdata) => ({
        ...prevdata,
        [name]: value,
      }));
    }
  };

  const handleCreate = async () => {
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (
        formData.username !== "" &&
        formData.role !== "" &&
        formData.email !== "" &&
        emailRegex.test(formData.email) &&
        formData.password !== "" &&
        formData.mobile !== ""
      ) {
        const form = new FormData();
        form.append("profilePicture", formData.profilePicture);
        form.append("username", JSON.stringify(formData.username));
        form.append("role", JSON.stringify(formData.role));
        form.append("email", JSON.stringify(formData.email));
        form.append("password", JSON.stringify(formData.password));
        form.append("mobile", formData.mobile);
        form.append("address", JSON.stringify(formData.address));

        form.forEach((value, key) => {
          if (value instanceof File) {
            console.log(`${key}:`, value.name, value.size, value.type);
          } else {
            console.log(`${key}:`, value);
          }
        });

        const res = await axios.post(`${config.BASE_URL}/users-create`, form, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (res.status === 200) {
          toast.success("New User Added!");
          setFormData({
            username: "",
            email: "",
            password: "",
            mobile: "",
            role: "",
            address: "",
            profilePicture: null,
          });
          setShow(false);
          fetchUsers();
        }
      } else {
        toast.warning("please enter all the fields");
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  const handleUpdate = async (id) => {
    try {
      if (
        formData.username !== "" &&
        formData.role !== "" &&
        formData.email !== "" &&
        formData.password !== "" &&
        formData.mobile !== ""
      ) {
        const form = new FormData();
        if (editFormData && editFormData.profilePicture)
          form.append("profilePicture", editFormData.profilePicture);
        if (editFormData && editFormData.username)
          form.append("username", JSON.stringify(editFormData.username));
        if (editFormData && editFormData.role)
          form.append("role", JSON.stringify(editFormData.role));
        if (editFormData && editFormData.email)
          form.append("email", JSON.stringify(editFormData.email));
        if (editFormData && editFormData.password)
          form.append("password", JSON.stringify(editFormData.password));
        if (editFormData && editFormData.mobile)
          form.append("mobile", editFormData.mobile);
        if (editFormData && editFormData.address)
          form.append("address", JSON.stringify(editFormData.address));

        // form.forEach((value, key) => {
        //   if (value instanceof File) {
        //     // If the value is a file, log the file name and size
        //     console.log(`${key}: ${value.name} (${value.size} bytes)`);
        //   } else {
        //     // For other types of values, just log the value
        //     console.log(`${key}: ${value}`);
        //   }
        // });

        const res = await axios.put(
          `${config.BASE_URL}/users-update/${id}`,
          form,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (res.status === 200) {
          toast.success("User data Updated!");
          setFormData({
            username: "",
            email: "",
            password: "",
            mobile: "",
            role: "",
            address: "",
            profilePicture: null,
          });
          setEditFormData({
            username: "",
            email: "",
            password: "",
            mobile: "",
            role: "",
            address: "",
            profilePicture: null,
          });
          setEditShow(false);
          fetchUsers();
        }
      } else {
        toast.warning("please enter all the fields");
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchRoles();
  }, []);

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>User List</h4>
            <h6>Manage your User</h6>
          </div>
          {permission.user.create && (
            <>
              <div className="page-btn">
                <a onClick={() => setShow(true)} className="btn btn-added">
                  <img src="/img/icons/plus.svg" alt="img" className="me-2" />{" "}
                  Add User
                </a>
              </div>
              <div className="page-btn-mob">
                <a
                  onClick={() => setShow(true)}
                  className="btn btn-added btn-added-mob"
                >
                  <img src="/img/icons/plus.svg" alt="img" />
                </a>
              </div>
            </>
          )}
        </div>

        <div className="card">
          <div className="card-body">
            {users && <DataTable columns={tableHead} data={users} pagination />}
          </div>
        </div>
        <Modal show={show} size="lg" onHide={() => setShow(true)}>
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>User Name</label>
                    <input
                      type="text"
                      name="username"
                      value={formData.username}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>

                    <input
                      type="text"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Phone No.</label>
                    <input
                      type="number"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Role</label>
                    <select
                      className="select"
                      name="role"
                      value={formData.role}
                      onChange={handleChange}
                    >
                      <option value={""} disabled>
                        Select Role
                      </option>
                      {role &&
                        role.map((element) => (
                          <option key={element._id} value={element.role}>
                            {element.role}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Address</label>

                    <textarea
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Profile Picture</label>
                    <div className="image-upload image-upload-new">
                      <input
                        type="file"
                        accept="image/*"
                        name="profilePicture"
                        onChange={handleChange}
                      />
                      <div className="image-uploads">
                        <img src="/img/icons/upload.svg" alt="img" />
                        <h4>Drag and drop a file to upload</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={handleCreate}
                    className="btn btn-submit me-2"
                  >
                    Create
                  </button>
                  <button
                    type="button"
                    onClick={() => setShow(false)}
                    className="btn btn-cancel"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal show={editShow} size="lg" onHide={() => setEditShow(true)}>
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>User Name</label>
                    <input
                      type="text"
                      name="username"
                      value={formData.username}
                      onChange={handleEditChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleEditChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>

                    <input
                      type="text"
                      name="password"
                      value={formData.password}
                      onChange={handleEditChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Phone No.</label>
                    <input
                      type="number"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleEditChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Role</label>
                    <select
                      className="select"
                      name="role"
                      value={formData.role}
                      onChange={handleEditChange}
                    >
                      <option value={""} disabled>
                        Select Role
                      </option>
                      {role &&
                        role.map((element) => (
                          <option key={element._id} value={element.role}>
                            {element.role}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Address</label>

                    <textarea
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleEditChange}
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Profile Picture</label>
                    <div className="image-upload image-upload-new">
                      <input
                        type="file"
                        accept="image/*"
                        name="profilePicture"
                        onChange={handleEditChange}
                      />
                      <div className="image-uploads">
                        <img src="/img/icons/upload.svg" alt="img" />
                        <h4>Drag and drop a file to upload</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={() => handleUpdate(formData._id)}
                    className="btn btn-submit me-2"
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    onClick={() => setEditShow(false)}
                    className="btn btn-cancel"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default UserList;

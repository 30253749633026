import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { IoArrowBackSharp } from "react-icons/io5";

const PPF = () => {
  const [value, setValue] = useState({
    frequency: 12,
    investment: 1000,
    ppfRate: 7.1,
    duration: 15,
  });
  const [result, setResult] = useState(0);
  const [visible, setVisible] = useState({
    rate: false,
    age: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "investment") {
      if (/^\d*$/.test(value)) {
        setValue((prevdata) => ({
          ...prevdata,
          [name]: value,
        }));
      }
    } else {
      setValue((prevdata) => ({
        ...prevdata,
        [name]: value,
      }));
    }
  };
  const check = () => {
    if (Number(value.contribution) < 12) {
      setVisible((prevdata) => ({
        ...prevdata,
        rate: true,
      }));
    } else {
      setVisible((prevdata) => ({
        ...prevdata,
        rate: false,
      }));
    }
    if (Number(value.presentAge) > Number(value.retireAge)) {
      setVisible((prevdata) => ({
        ...prevdata,
        age: true,
      }));
    } else {
      setVisible((prevdata) => ({
        ...prevdata,
        age: false,
      }));
    }
  };

  useEffect(() => {
    calculate();
  }, []);

  useEffect(() => {
    calculate();
  }, [value]);

  const calculate = () => {
    check();
    const i = Number(value.ppfRate) / 100;
    let p = Number(value.frequency) * Number(value.investment);
    let middleterm = Math.pow(1 + i, Number(value.duration));
    let result =
      Number(value.investment) *
      Number(value.frequency) *
      ((middleterm - 1) / i);
    setResult(result);
  };

  const goBack = () => {
    window.history.back();
  };
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="d-flex align-items-center mb-5 gap-4">
          <IoArrowBackSharp
            onClick={goBack}
            style={{ fontSize: "1.5rem", cursor: "pointer" }}
          />
          <h1>PPF Calculator</h1>
        </div>
        <Row className="align-items-center">
          <Col className="col-">
            <Form>
              <Form.Group className="mb-4">
                <Form.Label>Frequency of Investment</Form.Label>
                <Form.Select
                  type="text"
                  name="frequency"
                  value={value.frequency}
                  onChange={handleChange}
                >
                  <option value={12}>Monthly</option>
                  <option value={4}>Quaterly</option>
                  <option value={2}>Half-Yearly</option>
                  <option value={1}>Yearly</option>
                </Form.Select>

                {visible.age && (
                  <Form.Text className="text-danger">
                    Error: Present Age can't be greater than Retirement Age
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Monthly PPF Investment</Form.Label>
                <Form.Control
                  type="text"
                  name="investment"
                  onChange={handleChange}
                  value={value.investment}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Current PPF Interest Rate (2022)</Form.Label>
                <Form.Control
                  type="text"
                  name="contribution"
                  readOnly
                  value={value.ppfRate + " %"}
                />
                {visible.rate && (
                  <Form.Text className="text-danger">
                    Error: Rate can't be less than 12 %
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Duration of Investment (15-50 years)</Form.Label>
                <Form.Control
                  type="text"
                  value={value.duration + " Years"}
                  readOnly
                />
                <Row className="align-items-center">
                  <Col xs="auto">
                    <Form.Label>15</Form.Label>
                  </Col>
                  <Col>
                    <Form.Range
                      min={15}
                      max={50}
                      value={value.duration}
                      name="duration"
                      onChange={handleChange}
                    />
                  </Col>
                  <Col xs="auto">
                    <Form.Label>50</Form.Label>
                  </Col>
                </Row>
                {visible.age && (
                  <Form.Text className="text-danger">
                    Error: Present Age can't be greater than Retirement Age
                  </Form.Text>
                )}
              </Form.Group>
            </Form>
          </Col>
          <Col className=" d-flex justify-content-center">
            <div className="p-5 border rounded border-warning shadow text-center">
              <p style={{ color: "gray", fontSize: "1.2rem" }}>
                PPF Maturity Amount
              </p>{" "}
              <h2>
                <strong>
                  {" "}
                  ₹{" "}
                  {result.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                  })}{" "}
                </strong>
              </h2>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PPF;

import axios from "axios";
import React, { useContext, useState } from "react";
import config from "../../Config";
import { AuthContext } from "../Context/AuthContext";
import { toast } from "react-toastify";

const Permissions = ({ setShow, fetchData }) => {
  const { logout } = useContext(AuthContext);
  const list = [
    "item",
    "category",
    "purchase",
    "payment",
    "vendor",
    "user",
    "sales",
    "roleAndPermission",
    "parties",
    "godowns",
    "stores"
  ];
  const token = localStorage.getItem("token");
  const [roleData, setRoleData] = useState({
    role: "",
    description: "",
    permissions: {
      item: { view: false, create: false, edit: false, delete: false },
      category: { view: false, create: false, edit: false, delete: false },
      purchase: { view: false, create: false, edit: false, delete: false },
      payment: { view: false, create: false, edit: false, delete: false },
      vendor: { view: false, create: false, edit: false, delete: false },
      user: { view: false, create: false, edit: false, delete: false },
      sales: { view: false, create: false, edit: false, delete: false },
      parties: { view: false, create: false, edit: false, delete: false },
      godowns: { view: false, create: false, edit: false, delete: false },
      stores: { view: false, create: false, edit: false, delete: false },
      roleAndPermission: {
        view: false,
        create: false,
        edit: false,
        delete: false,
      },
    },
  });
  const handleCreate = async () => {
    try {
      console.log(roleData);
      if (
        roleData.role !== "" &&
        roleData.role !== "Super Admin" &&
        roleData.description !== ""
      ) {
        const res = await axios.post(`${config.BASE_URL}/addrole`, roleData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (res.status === 200) {
          toast.success("New Role Added!");
          setShow(false);
          fetchData();
        }
      } else {
        if (roleData.role === "Super Admin") {
          toast.warning("Please enter other name than Super Admin");
        } else toast.warning("Enter role and Description!");
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      const [section, permission] = name.split(".");
      setRoleData((prevState) => ({
        ...prevState,
        permissions: {
          ...prevState.permissions,
          [section]: {
            ...prevState.permissions[section],
            [permission]: checked,
          },
        },
      }));
    } else {
      setRoleData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <div className="card mb-0">
      <div className="card-body">
        <div className="row">
          <div className="col-lg-3 col-sm-12">
            <div className="form-group">
              <label>Role</label>
              <input
                type="text"
                value={roleData.role}
                onChange={handleChange}
                name="role"
              />
            </div>
          </div>
          <div className="col-lg-9 col-sm-12">
            <div className="form-group">
              <label>Role Description</label>
              <input
                type="text"
                value={roleData.description}
                onChange={handleChange}
                name="description"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="productdetails product-respon">
              <ul>
                {list.map((section, index) => (
                  <li key={index}>
                    <h4>{section}</h4>
                    <div className="input-checkset">
                      <ul>
                        {["view", "create", "edit", "delete"].map(
                          (permission) => (
                            <li key={permission}>
                              <label className="inputcheck">
                                {permission.charAt(0).toUpperCase() +
                                  permission.slice(1)}
                                <input
                                  type="checkbox"
                                  name={`${section}.${permission}`}
                                  checked={
                                    roleData.permissions[section][permission]
                                  }
                                  onChange={handleChange}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-lg-12 mt-2 d-flex justify-content-end">
            <a onClick={handleCreate} className="btn btn-submit me-2">
              Submit
            </a>
            <a onClick={() => setShow(false)} className="btn btn-cancel">
              Cancel
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Permissions;

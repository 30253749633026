import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ItemContext } from "../Context/ItemContext";
import { CategoryContext } from "../Context/CategoryContext";
import { AuthContext } from "../Context/AuthContext";
import { toast } from "react-toastify";
import Select from "react-select";
import axios from "axios";
import config from "../../Config";

const CreateItem = () => {
  const [show, setShow] = useState(false);
  const [gstData, setGstData] = useState([]);
  const { permission } = useContext(AuthContext);
  const [editShow, setEditShow] = useState(false);
  const [applicable, setApplicable] = useState(false);
  const token = localStorage.getItem("token");
  const [formData, setFormData] = useState({
    itemName: "",
    category: "",
    unitPrice: 0,
    hsnCode: "",
    stockQuantity: 0,
    gstRate: 0,
    description: "",
    minimumlevel: 0,
    maximumlevel: 0,
  });
  const { items, createItem, updateItem, deleteItem } = useContext(ItemContext);
  const { category } = useContext(CategoryContext);
  const tableHead = [
    {
      name: "SNo",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "70px",
    },
    {
      name: "Item Name",
      selector: (row) => row.itemName,
      sortable: true,
    },
    {
      name: "Cetegory",
      selector: (row) => row.category,
      sortable: true,
    },
    {
      name: "Unit Price",
      selector: (row) => row.unitPrice,
      sortable: true,
    },
    {
      name: "Stock Quantity",
      selector: (row) => row.stockQuantity,
      sortable: true,
    },
    {
      name: "GST Rate %",
      selector: (row) => row.gstRate,
      sortable: true,
    },
    {
      name: "HSN Code",
      selector: (row) => row.hsnCode,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: false,
    },
    ...(permission.item.edit || permission.item.delete
      ? [
          {
            name: "Action",
            selector: (row) => (
              <>
                {permission.item.edit && (
                  <a onClick={() => handleEditClick(row)} className="me-3">
                    <img src="/img/icons/edit.svg" alt="img" />
                  </a>
                )}
                {permission.item.delete && (
                  <a
                    onClick={() => handleDelete(row._id)}
                    className="confirm-text"
                  >
                    <img src="/img/icons/delete.svg" alt="img" />
                  </a>
                )}
              </>
            ),
            sortable: false,
          },
        ]
      : []),
  ];

  const handleDelete = (id) => {
    const isConfirm = window.confirm(
      "Are you sure, you want to delete the item?"
    );
    if (isConfirm) {
      try {
        console.log(id);
        deleteItem(id);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleEditClick = (data) => {
    setFormData(data);
    if (data.hsnCode !== "") {
      setApplicable(true);
    }
    setEditShow(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };

  const handleCreate = () => {
    try {
      if (
        formData.itemName !== "" &&
        formData.category !== "" &&
        formData.unitPrice !== 0 &&
        formData.stockQuantity !== 0 &&
        (applicable ? formData.hsnCode !== "" : true) &&
        formData.description
      ) {
        console.log(formData);
        createItem(formData);
        setFormData({
          itemName: "",
          category: "",
          unitPrice: 0,
          hsnCode: "",
          stockQuantity: 0,
          gstRate: 0,
          description: "",
          minimumlevel: 0,
          maximumlevel: 0,
        });
        setApplicable(false);
        setShow(false);
      } else {
        toast.warning("Enter all fields!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = (id) => {
    try {
      updateItem(id, formData);
      setFormData({
        itemName: "",
        category: "",
        unitPrice: 0,
        stockQuantity: 0,
        gstRate: 0,
        description: "",
        minimumlevel: 0,
        maximumlevel: 0,
      });
      setApplicable(false);
      setEditShow(false);
    } catch (error) {
      console.error(error);
    }
  };

  const categoryOptions = category.map((element) => ({
    value: element._id,
    label: element.categoryname,
  }));
  const GstOptions = gstData.map((element) => ({
    value: { gstRate: element.gstRate, hsnCode: element.hsnCode },
    label: `${element.hsnCode} (${element.gstRate}%)`,
  }));

  const fetchGST = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/gst`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        setGstData(res.data.data);
      }
    } catch (error) {
      toast.error("Something went Wrong");
    }
  };

  const handleSelectChange = (selectedOption, name) => {
    if (name === "gstRate") {
      setFormData((prevdata) => ({
        ...prevdata,
        [name]: selectedOption.value.gstRate,
        hsnCode: selectedOption.value.hsnCode,
      }));
    } else {
      setFormData((prevdata) => ({
        ...prevdata,
        [name]: selectedOption.value,
      }));
    }
  };
  const handleApply = (e) => {
    setApplicable(e.target.value);
  };

  useEffect(() => {
    fetchGST();
  }, []);

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Item Ledger</h4>
            <h6>All Items</h6>
          </div>
          {permission.item.create && (
            <>
              <div className="page-btn">
                <a
                  onClick={() => {
                    setShow(true);
                    setFormData({
                      itemName: "",
                      category: "",
                      unitPrice: 0,
                      stockQuantity: 0,
                      gstRate: 0,
                      description: "",
                      minimumlevel: 0,
                      maximumlevel: 0,
                    });
                  }}
                  className="btn btn-added"
                >
                  <img src="/img/icons/plus.svg" alt="img" className="me-2" />{" "}
                  Create Item
                </a>
              </div>
              <div className="page-btn-mob">
                <a
                  onClick={() => {
                    setShow(true);
                    setFormData({
                      itemName: "",
                      category: "",
                      unitPrice: 0,
                      stockQuantity: 0,
                      gstRate: 0,
                      description: "",
                      minimumlevel: 0,
                      maximumlevel: 0,
                    });
                  }}
                  className="btn btn-added btn-added-mob"
                >
                  <img src="/img/icons/plus.svg" alt="img" />
                </a>
              </div>
            </>
          )}
        </div>

        <div className="card">
          <div className="card-body">
            <DataTable columns={tableHead} data={items} pagination />
          </div>
          <Modal size="lg" show={show} onHide={() => setShow(false)}>
            <div className="card mb-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Item Name</label>
                      <input
                        type="text"
                        name="itemName"
                        placeholder="Enter Item Name"
                        value={formData.itemName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label> Category </label>
                      <Select
                        defaultInputValue={formData.category}
                        options={categoryOptions}
                        onChange={(selectedOption) =>
                          handleSelectChange(selectedOption, "category")
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Unit Price</label>
                      <input
                        type="number"
                        name="unitPrice"
                        placeholder="Enter Unit Price"
                        value={formData.unitPrice}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="mb-0">GST Applicable </label>
                      <div className="d-flex gap-3">
                        <div className="d-flex  align-items-center gap-2">
                          <input
                            type="radio"
                            checked={applicable}
                            onChange={() => setApplicable(true)}
                          />
                          <label className="mb-0">Yes</label>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            checked={!applicable}
                            onChange={() => setApplicable(false)}
                          />
                          <label className="mb-0">No</label>
                        </div>
                      </div>
                      <Select
                        defaultInputValue={formData.hsnCode}
                        isDisabled={!applicable}
                        options={GstOptions}
                        onChange={(selectedOption) =>
                          handleSelectChange(selectedOption, "gstRate")
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Stock Quantity</label>
                      <input
                        type="number"
                        name="stockQuantity"
                        placeholder="Enter Stock Quantity"
                        value={formData.stockQuantity}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label> Minimum Level</label>
                      <input
                        type="number"
                        name="minimumlevel"
                        placeholder="Enter Minimum Level of Item"
                        value={formData.minimumlevel}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Maximum Level</label>
                      <input
                        type="number"
                        name="maximumlevel"
                        placeholder="Enter Maximum Level of Item"
                        value={formData.maximumlevel}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Description</label>
                      <div className="pass-group">
                        <textarea
                          type="text"
                          className="pass-inputs"
                          name="description"
                          placeholder="Enter Description"
                          value={formData.description}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 d-flex justify-content-end">
                    <button
                      className="btn btn-submit me-2"
                      onClick={handleCreate}
                    >
                      Submit
                    </button>
                    <button
                      onClick={() => {
                        setShow(false);
                        setFormData({
                          itemName: "",
                          category: "",
                          unitPrice: 0,
                          stockQuantity: 0,
                          gstRate: 0,
                          description: "",
                          minimumlevel: 0,
                          maximumlevel: 0,
                        });
                      }}
                      className="btn btn-cancel"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal size="lg" show={editShow} onHide={() => setEditShow(false)}>
            <div className="card mb-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Item Name</label>
                      <input
                        type="text"
                        name="itemName"
                        placeholder="Enter Item Name"
                        value={formData.itemName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label> Category </label>
                      <Select
                        options={categoryOptions}
                        defaultInputValue={formData.category}
                        onChange={(selectedOption) =>
                          handleSelectChange(selectedOption, "category")
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Unit Price</label>
                      <input
                        type="number"
                        name="unitPrice"
                        placeholder="Enter Unit Price"
                        value={formData.unitPrice}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="mb-0">GST Applicable </label>
                      <div className="d-flex gap-3">
                        <div className="d-flex  align-items-center gap-2">
                          <input
                            type="radio"
                            checked={applicable}
                            onChange={() => setApplicable(true)}
                          />
                          <label className="mb-0">Yes</label>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            checked={!applicable}
                            onChange={() => setApplicable(false)}
                          />
                          <label className="mb-0">No</label>
                        </div>
                      </div>
                      <Select
                        defaultInputValue={formData.hsnCode}
                        isDisabled={!applicable}
                        options={GstOptions}
                        onChange={(selectedOption) =>
                          handleSelectChange(selectedOption, "gstRate")
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Stock Quantity</label>
                      <input
                        type="number"
                        name="stockQuantity"
                        placeholder="Enter Stock Quantity"
                        value={formData.stockQuantity}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Description</label>
                      <div className="pass-group">
                        <textarea
                          type="text"
                          className="pass-inputs"
                          name="description"
                          placeholder="Enter Description"
                          value={formData.description}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 d-flex justify-content-end">
                    <button
                      className="btn btn-submit me-2"
                      onClick={() => handleUpdate(formData._id)}
                    >
                      Update
                    </button>
                    <button
                      onClick={() => {
                        setEditShow(false);
                        setFormData({
                          itemName: "",
                          category: "",
                          unitPrice: 0,
                          stockQuantity: 0,
                          gstRate: 0,
                          description: "",
                          minimumlevel: 0,
                          maximumlevel: 0,
                        });
                      }}
                      className="btn btn-cancel"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default CreateItem;

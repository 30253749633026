import React, { useContext, useRef, useState } from "react";
import { PurchaseContext } from "../Context/PurchaseContext";
import axios from "axios";
import config from "../../Config";
import { AuthContext } from "../Context/AuthContext";
import { toast } from "react-toastify";

const BillUpload = () => {
  const fileInputRef = useRef(null);
  const { purchases } = useContext(PurchaseContext);
  const {logout} = useContext(AuthContext);
  const [billData, setBillData] = useState({
    purchaseOrder: "",
    date: "",
    uploadFile: null,
  });
  const token = localStorage.getItem('token');

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "uploadFile") {
      const file = files[0];
      setBillData((prevdata) => ({
        ...prevdata,
        [name]: file,
      }));
    } else {
      setBillData((prevdata) => ({
        ...prevdata,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      if (
        billData.date !== "" &&
        billData.purchaseOrder !== "" &&
        billData.uploadFile
      ) {
        const formdata = new FormData();
        formdata.append("uploadFile", billData.uploadFile);
        formdata.append(
          "purchaseOrder",
          JSON.stringify(billData.purchaseOrder)
        );
        formdata.append("date", JSON.stringify(billData.date));

        const res = await axios.post(`${config.BASE_URL}/bill`,formdata,{
          headers: {'Authorization': `Bearer ${token}`},
        });
        if(res.status === 200) {
          toast.success("Bill uploaded!");
          setBillData({
            purchaseOrder: "",
            date: "",
            uploadFile: null,
          })
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        }
      }
      else{
        toast.warning("Fill all Fields")
      }
    } catch (error) {
      if(error.response.status === 401) {logout(); toast.error("Your token has expired please login again!");}
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Bill upload</h4>
            <h6>Upload your Bill</h6>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row my-4">
              <div className="col-lg-9 col-sm-10 col-10">
                <div className="form-group">
                  <label>Purchase Order</label>
                  <select className="select" name="purchaseOrder" value={billData.purchaseOrder} onChange={handleChange} >
                    <option value={""} disabled>
                      {" "}
                      Select Purchase{" "}
                    </option>
                    {purchases &&
                      purchases.map((element) => (
                        <option
                        key={element._id}
                          value={element._id}
                        >{`${element._id} ........Grand-Total: ₹${element.totalPrice}/-`}</option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-3 col-sm-10 col-10">
                <div className="form-group">
                  <label>Date</label>
                  <input className="input" type="date" name="date" onChange={handleChange} value={billData.date} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group">
                  <label> Upload File</label>

                  <input
                  ref={fileInputRef}
                    type="file"
                    name="uploadFile"
                    onChange={handleChange}
                    accept="image/*"
                  />
                </div>
              </div>

              <div className="col-lg-12 d-flex justify-content-end">
                <button type="button" className="btn btn-submit me-2" onClick={handleSubmit}>Upload</button>
                <button type="button" className="btn btn-cancel">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillUpload;

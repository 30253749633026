import Modal from "react-bootstrap/Modal";
import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { GodownContext } from "../Context/GodownContext";
import { AuthContext } from "../Context/AuthContext";
import { toast } from "react-toastify";

const Godown = () => {
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const { permission } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    godownName: "",
    capacity: "",
    location: "",
    description: "",
  });
  const { godowns, createGodown, updateGodown, deleteGodown } =
    useContext(GodownContext);
  // const { contactPerson } = useContext(CategoryContext);
  const tableHead = [
    {
      name: "SNo",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "70px",
    },
    {
      name: "Godown Name",
      selector: (row) => row.godownName,
      sortable: true,
    },
    {
      name: "Capacity",
      selector: (row) => row.capacity,
      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: false,
    },
    ...(permission.godowns.edit || permission.godowns.delete
      ? [
          {
            name: "Action",
            selector: (row) => (
              <>
                {permission.godowns.edit && (
                  <a onClick={() => handleEditClick(row)} className="me-3">
                    <img src="/img/icons/edit.svg" alt="edit" />
                  </a>
                )}
                {permission.godowns.delete && (
                  <a
                    onClick={() => handleDelete(row._id)}
                    className="confirm-text"
                  >
                    <img src="/img/icons/delete.svg" alt="delete" />
                  </a>
                )}
              </>
            ),
            sortable: false,
          },
        ]
      : []),
  ];

  const handleDelete = (id) => {
    const isConfirm = window.confirm(
      "Are you sure, you want to delete the Godown?"
    );
    if (isConfirm) {
      try {
        deleteGodown(id);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleEditClick = (data) => {
    setFormData(data);
    setEditShow(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };

  const handleCreate = () => {
    try {
      if (
        formData.godownName !== "" &&
        formData.capacity !== "" &&
        formData.location !== "" &&
        formData.description !== ""
      ) {
        createGodown(formData);
        setFormData({
          godownName: "",
          capacity: "",
          location: "",
          description: "",
        });
        setShow(false);
      } else {
        toast.warning("Enter all Fields!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = (id) => {
    try {
      updateGodown(id, formData);
      setFormData({
        godownName: "",
        capacity: "",
        location: "",
        description: "",
      });
      setEditShow(false);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    console.log(permission);
  }, [permission]);

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Godown List</h4>
            <h6>Manage your Godowns</h6>
          </div>
          {permission.godowns.create && (
            <>
              <div className="page-btn">
                <a
                  href="#"
                  onClick={() => setShow(true)}
                  className="btn btn-added"
                >
                  <img src="/img/icons/plus.svg" alt="img" className="me-2" />{" "}
                  Add Godown
                </a>
              </div>
              <div className="page-btn-mob">
                <a
                  onClick={() => setShow(true)}
                  className="btn btn-added btn-added-mob"
                >
                  <img src="/img/icons/plus.svg" alt="img" />
                </a>
              </div>
            </>
          )}
        </div>

        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <DataTable columns={tableHead} data={godowns} pagination />
            </div>
          </div>
        </div>

        <Modal size="lg" show={show} onHide={() => setShow(false)}>
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Godown's Name</label>
                    <input
                      type="text"
                      name="godownName"
                      value={formData.godownName}
                      placeholder="Enter Godown's name"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Capacity</label>
                    <input
                      type="number"
                      name="capacity"
                      value={formData.capacity}
                      placeholder="Enter Capacity"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Location</label>
                    <div className="pass-group">
                      <textarea
                        type="text"
                        className="pass-inputs"
                        name="location"
                        value={formData.location}
                        placeholder="Enter Location"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Description</label>
                    <div className="pass-group">
                      <textarea
                        type="text"
                        name="description"
                        value={formData.description}
                        placeholder="Enter Description"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={handleCreate}
                    className="btn btn-submit me-2"
                  >
                    Create
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setShow(false);
                      setFormData({
                        godownName: "",
                        capacity: "",
                        location: "",
                        description: "",
                      });
                    }}
                    className="btn btn-cancel"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal size="lg" show={editShow} onHide={() => setEditShow(false)}>
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Godown's Name</label>
                    <input
                      type="text"
                      name="godownName"
                      value={formData.godownName}
                      placeholder="Enter Godown's name"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Capacity</label>
                    <input
                      type="number"
                      name="capacity"
                      value={formData.capacity}
                      placeholder="Enter Capacity"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Location</label>
                    <div className="pass-group">
                      <textarea
                        type="text"
                        name="location"
                        value={formData.location}
                        placeholder="Enter Location"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Description</label>
                    <div className="pass-group">
                      <input
                        type="text"
                        name="description"
                        value={formData.description}
                        placeholder="Enter Description"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={() => handleUpdate(formData._id)}
                    className="btn btn-submit me-2"
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setEditShow(false);
                      setFormData({
                        godownName: "",
                        capacity: "",
                        location: "",
                        description: "",
                      });
                    }}
                    className="btn btn-cancel"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Godown;

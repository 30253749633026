import Modal from "react-bootstrap/Modal";
import React, { useContext, useState } from "react";
import DataTable from "react-data-table-component";
import { VendorContext } from "../Context/VendorContext";
import { AuthContext } from "../Context/AuthContext";
import { toast } from "react-toastify";

const CreateVendor = () => {
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const { permission } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    vendorsname: "",
    contactperson: "",
    mobile: "",
    email: "",
    address: "",
    description: "",
  });
  const { vendors, createVendor, updateVendor, deleteVendor } =
    useContext(VendorContext);
  // const { contactPerson } = useContext(CategoryContext);
  const tableHead = [
    {
      name: "Vendors Name",
      selector: (row) => row.vendorsname,
      sortable: true,
    },
    {
      name: "Contact Person",
      selector: (row) => row.contactperson,
      sortable: true,
    },
    {
      name: "Phone No.",
      selector: (row) => row.mobile,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: false,
    },
    ...(permission.vendor.edit || permission.vendor.delete
      ? [
          {
            name: "Action",
            selector: (row) => (
              <>
                {permission.vendor.edit && (
                  <a onClick={() => handleEditClick(row)} className="me-3">
                    <img src="/img/icons/edit.svg" alt="edit" />
                  </a>
                )}
                {permission.vendor.delete && (
                  <a
                    onClick={() => handleDelete(row._id)}
                    className="confirm-text"
                  >
                    <img src="/img/icons/delete.svg" alt="delete" />
                  </a>
                )}
              </>
            ),
            sortable: false,
          },
        ]
      : []),
  ];

  const handleDelete = (id) => {
    const isConfirm = window.confirm(
      "Are you sure, you want to delete the vendor?"
    );
    if (isConfirm) {
      try {
        deleteVendor(id);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleEditClick = (data) => {
    setFormData(data);
    setEditShow(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const regex = /^[+]?\d*$/;
    if (name === "mobile") {
      if (regex.test(value)) {
        setFormData((prevdata) => ({
          ...prevdata,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevdata) => ({
        ...prevdata,
        [name]: value,
      }));
    }
  };

  const handleCreate = () => {
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (
        formData.vendorsname !== "" &&
        formData.contactperson !== "" &&
        formData.mobile !== "" &&
        formData.email !== "" &&
        emailRegex.test(formData.email) &&
        formData.address !== "" &&
        formData.description !== ""
      ) {
        createVendor(formData);
        setFormData({
          vendorsname: "",
          contactperson: "",
          mobile: "",
          email: "",
          address: "",
          description: "",
        });
        setShow(false);
      } else {
        toast.warning("Enter all Fields!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = (id) => {
    try {
      updateVendor(id, formData);
      setFormData({
        vendorsname: "",
        contactperson: "",
        mobile: "",
        email: "",
        address: "",
        description: "",
      });
      setEditShow(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Vendor List</h4>
            <h6>Manage your Vendors</h6>
          </div>
          {permission.vendor.create && (
            <>
              <div className="page-btn">
                <a onClick={() => setShow(true)} className="btn btn-added">
                  <img src="/img/icons/plus.svg" alt="img" className="me-2" />{" "}
                  Add Vendor
                </a>
              </div>
              <div className="page-btn-mob">
                <a
                  onClick={() => setShow(true)}
                  className="btn btn-added btn-added-mob"
                >
                  <img src="/img/icons/plus.svg" alt="img" />
                </a>
              </div>
            </>
          )}
        </div>

        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <DataTable columns={tableHead} data={vendors} pagination />
            </div>
          </div>
        </div>

        <Modal size="lg" show={show} onHide={() => setShow(false)}>
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Vendor's Name</label>
                    <input
                      type="text"
                      name="vendorsname"
                      value={formData.vendorsname}
                      placeholder="Enter Vendor's name"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Contact Person</label>
                    <input
                      type="text"
                      name="contactperson"
                      value={formData.contactperson}
                      placeholder="Enter Contact Person's Name"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      placeholder="Enter Email"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Mobile</label>
                    <input
                      type="tel"
                      name="mobile"
                      value={formData.mobile}
                      placeholder="Enter Phone Number"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Address</label>
                    <div className="pass-group">
                      <textarea
                        type="text"
                        className="pass-inputs"
                        name="address"
                        value={formData.address}
                        placeholder="Enter Address"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Description</label>
                    <div className="pass-group">
                      <textarea
                        type="text"
                        className="pass-inputs"
                        name="description"
                        value={formData.description}
                        placeholder="Enter Description"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={handleCreate}
                    className="btn btn-submit me-2"
                  >
                    Create
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setShow(false);
                      setFormData({
                        vendorsname: "",
                        contactperson: "",
                        mobile: "",
                        email: "",
                        address: "",
                        description: "",
                      });
                    }}
                    className="btn btn-cancel"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal size="lg" show={editShow} onHide={() => setEditShow(false)}>
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Vendor's Name</label>
                    <input
                      type="text"
                      name="vendorsname"
                      value={formData.vendorsname}
                      placeholder="Enter Vendor's name"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Contact Person</label>
                    <input
                      type="text"
                      name="contactperson"
                      value={formData.contactperson}
                      placeholder="Enter Contact Person's Name"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      placeholder="Enter Email"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Mobile</label>
                    <input
                      type="phone"
                      name="mobile"
                      value={formData.mobile}
                      placeholder="Enter Phone Number"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Address</label>
                    <div className="pass-group">
                      <textarea
                        type="text"
                        className="pass-inputs"
                        name="address"
                        value={formData.address}
                        placeholder="Enter Address"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Description</label>
                    <div className="pass-group">
                      <textarea
                        type="text"
                        className="pass-inputs"
                        name="description"
                        value={formData.description}
                        placeholder="Enter Description"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={() => handleUpdate(formData._id)}
                    className="btn btn-submit me-2"
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setEditShow(false);
                      setFormData({
                        vendorsname: "",
                        contactperson: "",
                        mobile: "",
                        email: "",
                        address: "",
                        description: "",
                      });
                    }}
                    className="btn btn-cancel"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default CreateVendor;

import React, { useContext, useEffect, useRef, useState } from "react";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import axios from "axios";
import config from "../../Config";
import { ItemContext } from "../Context/ItemContext";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";

const Navbar = () => {
  const [sideOpen, setSideOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [notifyOpen, setNotifyOpen] = useState(false);
  const [dropOpen, setDropOpen] = useState(false);
  const [settingsData, setSettingsData] = useState(null);
  const dropRef = useRef();
  const profileRef = useRef();
  const notificationRef = useRef();
  const notifyListRef = useRef();
  const { logout, user } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const {getNotifications, notifications } = useContext(ItemContext);
  const [count, setCount] = useState(0);

  const notifyUnread = async (id) => {
    try {
      const res = await axios.put(
        `${config.BASE_URL}/notification/${id}`,
        {
          status: "read",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    if(res.status === 200){
      getNotifications();
    }
    } catch (error) {
      toast.error("Something Went Wrong!!");
    }
  };
  const notifyReset = async () => {
    try {
      const res = await axios.put(
        `${config.BASE_URL}/notifications/mark-all-read`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    if(res.status === 200){
      getNotifications();
    }
    } catch (error) {
      toast.error("Something Went Wrong!!");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        // Clicked outside of the profile area
        setProfileOpen(false);
      }
    };

    // Bind the event listener only when profile is open
    if (profileOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    // Cleanup: Remove the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [profileOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        notifyListRef.current &&
        !notifyListRef.current.contains(event.target) &&
        !notificationRef.current.contains(event.target)
      ) {
        // Clicked outside of the profile area
        setNotifyOpen(false);
      }
    };

    // Bind the event listener only when profile is open
    if (notifyOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    // Cleanup: Remove the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [notifyOpen]);

  const fetchSettings = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/all-settings`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        setSettingsData(res.data.data);
        console.log(res.data);
      }
    } catch (error) {
      if (error.response.status === 401) logout();
    }
  };
  useEffect(() => {
    fetchSettings();
  }, []);

  useEffect(() => {
    const link =
      document.querySelector("link[rel~='icon']") ||
      document.createElement("link");
    link.rel = "icon";
    const faviconUrl =
      settingsData && settingsData.favIcon
        ? `${config.IMG_URL}/${settingsData.favIcon}`
        : "";
    link.href = faviconUrl;
  }, [settingsData]);

  const mdopen = () => {
    setSideOpen(!sideOpen);
  };

  useEffect(()=>{
    const unreadCount = notifications.reduce((acc, element) => {
      return element.status === 'unread' ? acc + 1 : acc;
    }, 0);
    
    setCount(unreadCount);
  },[notifications])

  return (
    <div className="header">
      <div className="header-left active">
        <div className="logo">
          <img
            src={
              settingsData
                ? `${config.IMG_URL}/${settingsData.logo}`
                : "/img/stockSathi.png"
            }
            alt="logo"
          />
        </div>
        <div className="logo-small">
          <img src="/img/logo-small.png" alt="" />
        </div>
        <div id="toggle_btn">{/* Placeholder for toggle button */}</div>
      </div>

      <a
        id="mobile_btn"
        onClick={mdopen}
        className="mobile_btn"
        href="#sidebar"
      >
        <span className="bar-icon">
          <span></span>
          <span></span>
          <span></span>
        </span>
      </a>

      <ul className="nav user-menu">
        <li className="nav-item dropdown">
          <Link
            ref={notificationRef}
            onClick={() => setNotifyOpen(!notifyOpen)}
            className="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
          >
            <img src="/img/icons/notification-bing.svg" alt="img" />{" "}
            <span className="badge rounded-pill">{count}</span>
          </Link>
          <div
            ref={notifyListRef}
            className={`dropdown-menu notifications ${
              notifyOpen ? "show" : ""
            }`}
            style={{
              position: notifyOpen ? "absolute" : "static",
              inset: notifyOpen ? "0px 0px auto auto" : "auto",
              margin: notifyOpen ? "0px" : "auto",
              transform: "translate(0px, 62px)",
            }}
          >
            <div className="topnav-dropdown-header">
              <span className="notification-title">Notifications</span>
              <a onClick={notifyReset} className="clear-noti">
                {" "}
                Clear All{" "}
              </a>
            </div>
            <div
              className="noti-content"
              style={{ backgroundColor: "#fafbfe" }}
            >
             {count > 0 ? <ul className="notification-list">
                {notifications &&
                  notifications.map((item) => ( item.status === "unread" ?
                    <div
                    key={item._id}
                      className="m-1 d-flex justify-content-between py-2 px-1 border rounded-2 align-items-center"
                      style={{ backgroundColor: "#fff", color: "#666" }}
                    >
                      <p className="mb-0">
                        {item.message.slice(0, 30) + "...."}{" "}
                      </p>
                      <RxCross2
                        style={{ cursor: "pointer" }}
                        onClick={() => notifyUnread(item._id)}
                      />
                    </div>
                  : <></>))}{" "}
              </ul> : <div className="w-100 h-100 text-center d-flex align-items-center justify-content-center"><h3>No new notifications</h3></div>}
            </div>
            <div className="topnav-dropdown-footer">
              <Link to="/all-notifications">View all Notifications</Link>
            </div>
          </div>
        </li>

        <li className="nav-item dropdown has-arrow main-drop">
          <a
            ref={profileRef}
            className="dropdown-toggle nav-link userset"
            onClick={() => setProfileOpen(!profileOpen)}
            data-bs-toggle="dropdown"
          >
            <span className="user-img">
              {user.userImage ? (
                <img
                  src={`${config.IMG_URL}/${user.userImage}`}
                  alt="userImage"
                />
              ) : (
                <img src="/img/profile.png" alt="userImage" />
              )}
              <span className="status online"></span>
            </span>
          </a>
          <div
            className={`dropdown-menu menu-drop-user ${
              profileOpen ? "show" : ""
            }`}
            style={{
              position: profileOpen ? "absolute" : "static",
              inset: profileOpen ? "0px 0px auto auto" : "auto",
              margin: "0px",
              transform: profileOpen ? "translate(0px, 62px)" : "none",
            }}
          >
            <div className="profilename">
              <div className="profileset">
                <span className="user-img">
                  <img
                    src={`${config.IMG_URL}/${user.userImage}`}
                    alt="userImage"
                  />
                  <span className="status online"></span>
                </span>
                <div className="profilesets">
                  <h6>{user.userName}</h6>
                  <h5>{user.userRole}</h5>
                </div>
              </div>
              <hr className="m-0" />
              {/* <a className="dropdown-item" href="#"> <i className="me-2" data-feather="user"></i> My Profile</a>
              <a className="dropdown-item" href="#"><i className="me-2" data-feather="settings"></i>Settings</a>
              <hr className="m-0" /> */}
              <a
                className="dropdown-item logout pb-0"
                href="#"
                onClick={logout}
              >
                <img src="/img/icons/log-out.svg" className="me-2" alt="img" />
                Logout
              </a>
            </div>
          </div>
        </li>
      </ul>

      <div className="dropdown mobile-user-menu">
        <a
          ref={dropRef}
          className="nav-link dropdown-toggle"
          onClick={() => setDropOpen(!dropOpen)}
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="fa fa-ellipsis-v"></i>
        </a>
        <div
          className={`dropdown-menu dropdown-menu-right ${
            dropOpen ? "show" : ""
          }`}
          style={{
            position: "absolute",
            inset: "0px 0px auto auto",
            margin: "0px",
            transform: "translate3d(-20px, 62px, 0px)",
          }}
        >
          <button type="button" className="dropdown-item" onClick={logout}>
            Logout
          </button>
        </div>
      </div>
      <Sidebar sideOpen={sideOpen} mdopen={mdopen} />
    </div>
  );
};

export default Navbar;

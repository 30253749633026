import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import config from "../../Config";
import { AuthContext } from "./AuthContext";
import { toast } from "react-toastify";

const PartyContext = createContext();

const PartyProvider = ({ children }) => {
  const [parties, setParties] = useState([]);
  const token = localStorage.getItem("token");
  const { logout } = useContext(AuthContext);
  const getParties = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/parties`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        setParties(res.data.data);
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        
      }
    }
  };

  const createParty = async (data) => {
    try {
      const res = await axios.post(`${config.BASE_URL}/party`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        toast.success("Party Created!");
        getParties();
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  const updateParty = async (id, data) => {
    try {
      const res = await axios.put(
        `${config.BASE_URL}/party/${id}`,
        data,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (res.status === 200) {
        toast.success("Party Updated Succesfuly!");
        getParties();
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  const deleteParty = async (id) => {
    try {
      const res = await axios.delete(
        `${config.BASE_URL}/party/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (res.status === 200) {
        toast.success("Party deleted succesfully!");
        getParties();
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  useEffect(() => {
    getParties();
  }, []);
  return (
    <PartyContext.Provider
      value={{ getParties, parties, createParty, updateParty, deleteParty }}
    >
      {children}
    </PartyContext.Provider>
  );
};

export { PartyProvider, PartyContext };

import React from 'react';

const InventoryReport = () => {
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Inventory Report</h4>
            <h6>Manage your Inventory Report</h6>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-path">
                  <button className="btn btn-filter" id="filter_search">
                    <img src="/img/icons/filter.svg" alt="img" />
                    <span><img src="/img/icons/closes.svg" alt="img" /></span>
                  </button>
                </div>
                <div className="search-input">
                  <button className="btn btn-searchset"><img src="/img/icons/search-white.svg" alt="img" /></button>
                </div>
              </div>
              <div className="wordset">
                <ul>
                  <li><a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"><img src="/img/icons/pdf.svg" alt="img" /></a></li>
                  <li><a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"><img src="/img/icons/excel.svg" alt="img" /></a></li>
                  <li><a data-bs-toggle="tooltip" data-bs-placement="top" title="print"><img src="/img/icons/printer.svg" alt="img" /></a></li>
                </ul>
              </div>
            </div>

            <div className="card" id="filter_inputs">
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <div className="input-groupicon">
                        <input type="text" placeholder="From Date" className="datetimepicker" />
                        <div className="addonset">
                          <img src="/img/icons/calendars.svg" alt="img" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <div className="input-groupicon">
                        <input type="text" placeholder="To Date" className="datetimepicker" />
                        <div className="addonset">
                          <img src="/img/icons/calendars.svg" alt="img" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <select className="select">
                        <option>Choose Suppliers</option>
                        <option>Suppliers</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                    <div className="form-group">
                      <button className="btn btn-filters ms-auto">
                        <img src="/img/icons/search-whites.svg" alt="img" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table datanew">
                <thead>
                  <tr>
                    <th>
                      <label className="checkboxs">
                        <input type="checkbox" id="select-all" />
                        <span className="checkmarks"></span>
                      </label>
                    </th>
                    <th>Product Name</th>
                    <th>SKU</th>
                    <th>Category</th>
                    <th>Brand</th>
                    <th>Price</th>
                    <th>Unit</th>
                    <th>Instock qty</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <label className="checkboxs">
                        <input type="checkbox" />
                        <span className="checkmarks"></span>
                      </label>
                    </td>
                    <td className="productimgname">
                      <a className="product-img">
                        <img src="/img/product/product1.jpg" alt="product" />
                      </a>
                      <a href="#">Macbook pro</a>
                    </td>
                    <td>PT001</td>
                    <td>Computer</td>
                    <td>N/D</td>
                    <td>1500.00</td>
                    <td>pc</td>
                    <td>1356</td>
                  </tr>
                  <tr>
                    <td>
                      <label className="checkboxs">
                        <input type="checkbox" />
                        <span className="checkmarks"></span>
                      </label>
                    </td>
                    <td className="productimgname">
                      <a className="product-img">
                        <img src="/img/product/product2.jpg" alt="product" />
                      </a>
                      <a href="#">Orange</a>
                    </td>
                    <td>PT002</td>
                    <td>Fruits</td>
                    <td>N/D</td>
                    <td>10.00</td>
                    <td>kg</td>
                    <td>131</td>
                  </tr>
                  {/* Repeat similar structure for each row */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InventoryReport;

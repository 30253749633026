import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { IoArrowBackSharp } from "react-icons/io5";

const IncomeTax = () => {
  const [value, setValue] = useState({
    asstYear: "2022-2023",
    age: "0-60",
    grossIncome: 0,
    exemption: 0,
    otherIncome: 0,
    interestIncome: 0,
    rentalIncome: 0,
    selfOccupied: 0,
    letOut: 0,
    basicDeduct: 0,
    depositInterest: 0,
    medInsaurance: 0,
    donation: 0,
    eduLoan: 0,
    homeLoan: 0,
    nps: 0,
  });
  const [result, setResult] = useState({
    totalIncome: 0,
    totalDeductions: 0,
    totalIncomeFinal: 0,
    totalTaxOld: 0,
    totalTaxNew: 0,
  });
  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState(0);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "asstYear" || name === "age") {
      setValue((prevdata) => ({
        ...prevdata,
        [name]: value,
      }));
    } else {
      if (/^\d*$/.test(value)) {
        setValue((prevdata) => ({
          ...prevdata,
          [name]: value,
        }));
      }
    }
  };
  const check = () => {
    if (Number(value.presentAge) > Number(value.retireAge)) {
      setVisible((prevdata) => ({
        ...prevdata,
        age: true,
      }));
    } else {
      setVisible((prevdata) => ({
        ...prevdata,
        age: false,
      }));
    }
  };

  const calculate = () => {
    const totalIncomeValue =
      Number(value.grossIncome) +
      Number(value.otherIncome) +
      Number(value.interestIncome) +
      Number(value.rentalIncome) +
      Number(value.depositInterest);
    const totalDeductionsValue =
      Number(value.basicDeduct) +
      Number(value.donation) +
      Number(value.eduLoan) +
      Number(value.exemption) +
      Number(value.homeLoan) +
      Number(value.letOut) +
      Number(value.medInsaurance) +
      Number(value.nps) +
      Number(value.selfOccupied);
    const totalIncomeFinalValue = totalIncomeValue - totalDeductionsValue;
    let taxslab = 0;
    let taxslabnew = 0;
    let extratax = 0;
    let extrataxnew = 0;
    if (value.asstYear === "2022-2023") {
      if (value.age === "0-60") {
        if (totalIncomeFinalValue > 1000000) {
          taxslab = 30;
          extratax = 112500;
        } else if (totalIncomeFinalValue > 500000) {
          taxslab = 20;
          extratax = 12500;
        } else if (totalIncomeFinalValue > 250000) {
          taxslab = 5;
        }
      } else if (value.age === "60-80") {
        if (totalIncomeFinalValue > 1000000) {
          taxslab = 30;
          extratax = 110000;
        } else if (totalIncomeFinalValue > 500000) {
          taxslab = 20;
          extratax = 10000;
        } else if (totalIncomeFinalValue > 300000) {
          taxslab = 5;
        }
      } else if (value.age === "80+") {
        if (totalIncomeFinalValue > 1000000) {
          taxslab = 30;
          extratax = 100000;
        } else if (totalIncomeFinalValue > 500000) {
          taxslab = 20;
        }
      }
      if (totalIncomeFinalValue > 1500000) {
        taxslabnew = 30;
        extrataxnew = 187500;
      } else if (totalIncomeFinalValue > 1250000) {
        taxslabnew = 25;
        extrataxnew = 125000;
      } else if (totalIncomeFinalValue > 1000000) {
        taxslabnew = 20;
        extrataxnew = 75000;
      } else if (totalIncomeFinalValue > 750000) {
        taxslabnew = 15;
        extrataxnew = 37500;
      } else if (totalIncomeFinalValue > 500000) {
        taxslabnew = 10;
        extrataxnew = 12500;
      } else if (totalIncomeFinalValue > 250000) {
        taxslabnew = 5;
      }
    } else {
      if (value.age == "0-60") {
        if (totalIncomeFinalValue > 1000000) {
          taxslab = 30;
          extratax = 112500;
        } else if (totalIncomeFinalValue > 500000) {
          taxslab = 20;
          extratax = 12500;
        } else if (totalIncomeFinalValue > 250000) {
          taxslab = 5;
        }
      } else if (value.age == "60-80") {
        if (totalIncomeFinalValue > 1000000) {
          taxslab = 30;
        } else if (totalIncomeFinalValue > 500000) {
          taxslab = 20;
        } else if (totalIncomeFinalValue > 300000) {
          taxslab = 5;
        }
      } else if (value.age == "80+") {
        if (totalIncomeFinalValue > 1000000) {
          taxslab = 30;
        } else if (totalIncomeFinalValue > 500000) {
          taxslab = 20;
        }
      }
      if (totalIncomeFinalValue > 1500000) {
        taxslabnew = 30;
      } else if (totalIncomeFinalValue > 1250000) {
        taxslabnew = 25;
      } else if (totalIncomeFinalValue > 1000000) {
        taxslabnew = 20;
      } else if (totalIncomeFinalValue > 750000) {
        taxslabnew = 15;
      } else if (totalIncomeFinalValue > 500000) {
        taxslabnew = 10;
      } else if (totalIncomeFinalValue > 250000) {
        taxslabnew = 5;
      }
    }

    let totalTaxOldValue = (totalIncomeFinalValue * taxslab) / 100 + extratax;
    let totalTaxNewValue =
      (totalIncomeFinalValue * taxslabnew) / 100 + extrataxnew;
    setResult({
      totalIncome: totalIncomeValue.toLocaleString("en-IN", {
        maximumFractionDigits: 2,
      }),
      totalDeductions: totalDeductionsValue.toLocaleString("en-IN", {
        maximumFractionDigits: 2,
      }),
      totalIncomeFinal: totalIncomeFinalValue.toLocaleString("en-IN", {
        maximumFractionDigits: 2,
      }),
      totalTaxOld: totalTaxOldValue.toLocaleString("en-IN", {
        maximumFractionDigits: 2,
      }),
      totalTaxNew: totalTaxNewValue.toLocaleString("en-IN", {
        maximumFractionDigits: 2,
      }),
    });
  };
  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="page-wrapper">
      <div className="content">
      <div className="d-flex align-items-center mb-5 gap-4">
      <IoArrowBackSharp onClick={goBack} style={{fontSize:"1.5rem",cursor:"pointer"}} />
        <h1>Income Tax Calculator</h1>
        </div>
        <Row className="align-items-center">
          {page === 0 && (
            <Col>
              <Form>
                <Form.Group className="mb-4" style={{ fontSize: "1.5rem" }}>
                  <Form.Label>Assesment Year</Form.Label>
                  <Form.Check
                    type="radio"
                    label="2021 - 2022"
                    value="2021-2022"
                    name="asstYear"
                    checked={value.asstYear === "2021-2022"}
                    onChange={handleChange}
                  />
                  <Form.Check
                    type="radio"
                    label="2022 - 2023"
                    name="asstYear"
                    value="2022-2023"
                    checked={value.asstYear === "2022-2023"}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-4" style={{ fontSize: "1.5rem" }}>
                  <Form.Label>Select your age</Form.Label>
                  <Form.Check
                    type="radio"
                    label="0 to 60"
                    name="age"
                    checked={value.age === "0-60"}
                    value="0-60"
                    onChange={handleChange}
                  />
                  <Form.Check
                    type="radio"
                    label="60 to 80"
                    name="age"
                    value="60-80"
                    checked={value.age === "60-80"}
                    onChange={handleChange}
                  />
                  <Form.Check
                    type="radio"
                    label="80 & above"
                    name="age"
                    value="80+"
                    checked={value.age === "80+"}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Button className="w-100" onClick={() => setPage(2)}>
                  Next
                </Button>
              </Form>
            </Col>
          )}
          {page === 2 && (
            <Col className="col-">
              <Form>
                <Form.Group className="mb-4">
                  <Form.Label>Gross Salary Income</Form.Label>
                  <Form.Control
                    type="text"
                    name="grossIncome"
                    onChange={handleChange}
                    value={value.grossIncome}
                  />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>Exemptions and Deductions from Salary</Form.Label>
                  <Form.Control
                    type="text"
                    name="exemption"
                    onChange={handleChange}
                    value={value.exemption}
                  />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>Annual Income from Other Sources</Form.Label>
                  <Form.Control
                    type="text"
                    name="otherIncome"
                    onChange={handleChange}
                    value={value.otherIncome}
                  />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>Annual Income from Interest</Form.Label>
                  <Form.Control
                    type="text"
                    name="interestIncome"
                    onChange={handleChange}
                    value={value.interestIncome}
                  />
                </Form.Group>
              </Form>

              <Form>
                <Form.Group className="mb-4">
                  <Form.Label>
                    Annual Income from let-house property (rental income)
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="rentalIncome"
                    onChange={handleChange}
                    value={value.rentalIncome}
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    Annual Interest Paid on Home Loan (self-occupied)
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="selfOccupied"
                    onChange={handleChange}
                    value={value.selfOccupied}
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    Annual Interest Paid on Home Loan (let-out)
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="letOut"
                    onChange={handleChange}
                    value={value.letOut}
                  />
                </Form.Group>
              </Form>

              <Button className="w-100" onClick={() => setPage(3)}>
                {" "}
                Next{" "}
              </Button>
            </Col>
          )}
          {page === 3 && (
            <Col className="col-">
              <Form>
                <Form.Group className="mb-4">
                  <Form.Label>Basic Deductions -80C</Form.Label>
                  <Form.Control
                    type="text"
                    name="basicDeduct"
                    onChange={handleChange}
                    value={value.basicDeduct}
                  />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>Interest from Deposits - 80TTA</Form.Label>
                  <Form.Control
                    type="text"
                    name="depositInterest"
                    onChange={handleChange}
                    value={value.depositInterest}
                  />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>Medical Insurance - 80D</Form.Label>
                  <Form.Control
                    type="text"
                    name="medInsaurance"
                    onChange={handleChange}
                    value={value.medInsaurance}
                  />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>Donations to Charity - 80G</Form.Label>
                  <Form.Control
                    type="text"
                    name="donation"
                    onChange={handleChange}
                    value={value.donation}
                  />
                </Form.Group>
              </Form>

              <Form>
                <Form.Group className="mb-4">
                  <Form.Label>Interest on Educational Loan - 80E</Form.Label>
                  <Form.Control
                    type="text"
                    name="eduLoan"
                    onChange={handleChange}
                    value={value.eduLoan}
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>Interest on Housing Loan - 80EEA</Form.Label>
                  <Form.Control
                    type="text"
                    name="homeLoan"
                    onChange={handleChange}
                    value={value.homeLoan}
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    Employee's contribution to NPS - 80CCD
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="nps"
                    onChange={handleChange}
                    value={value.nps}
                  />
                </Form.Group>
              </Form>

              <Button className="w-100" onClick={calculate}>
                {" "}
                Calculate{" "}
              </Button>
            </Col>
          )}
          <Col className=" d-flex justify-content-center">
            <div className="p-5 border rounded border-warning shadow">
              <p className="mb-0" style={{ color: "gray", fontSize: "1.4rem" }}>
              Total gross income
              </p>{" "}
              <h2 className="mb-3">
                <strong>
                {result.totalIncome}
                </strong>
              </h2>
              <p className="mb-0" style={{ color: "gray", fontSize: "1.2rem" }}>
              Total deductions
              </p>{" "}
              <h3 className="mb-3">
                <strong>
                {result.totalDeductions}
                </strong>
              </h3>
              <p className="mb-0" style={{ color: "gray", fontSize: "1.2rem" }}>
              Total income after deductions
              </p>{" "}
              <h3 className="mb-3">
                <strong>
                {result.totalIncomeFinal}
                </strong>
              </h3>
              <p className="mb-0" style={{ color: "gray", fontSize: "1.2rem" }}>
              Total tax (Old regime)
              </p>{" "}
              <h3 className="mb-3">
                <strong>
                {result.totalTaxOld}
                </strong>
              </h3>
              <p className="mb-0" style={{ color: "gray", fontSize: "1.2rem" }}>
              Total tax (New regime)
              </p>{" "}
              <h3 className="mb-3">
                <strong>
                  {result.totalTaxNew}
                </strong>
              </h3>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default IncomeTax;

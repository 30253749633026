import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import config from "../../Config";
import { AuthContext } from "../Context/AuthContext";
import { toast } from "react-toastify";

const PasswordUpdate = () => {
 
  const [passwordData, setPasswordData] = useState({
    email: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const { logout } = useContext(AuthContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };

  const handleUpdate = async () => {
    try {
      if (
        passwordData.oldPassword &&
        passwordData.newPassword === passwordData.confirmPassword
      ) {
        console.log(passwordData);
        const res = await axios.put(
          `${config.BASE_URL}/user-update-password`,
          passwordData,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (res.status === 200) {
          toast.success("Password Updated!");
        }
      } else {
        if (passwordData.oldPassword)
          toast.warning("New Password & Confirm Password not matching!");
        else toast.warning("Enter Current Password!");
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      } else {
        toast.error(error);
      }
    }
  };
  useEffect(() => {
    
    setPasswordData((prevdata) => ({
      ...prevdata,
      email: email,
    }));
  }, [email]);

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Password-Update</h4>
            <h6>Manage your Password</h6>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="row mb-3">
            <h5 className="mb-1">Your Email</h5>
            <strong>{email}</strong>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="form-group">
                  <label>Current Password</label>
                  <div>
                    <input
                      type="text"
                      value={passwordData.oldPassword}
                      onChange={handleChange}
                      name="oldPassword"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="form-group">
                  <label>New Password</label>
                  <div>
                    <input
                      type="text"
                      value={passwordData.newPassword}
                      onChange={handleChange}
                      name="newPassword"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="form-group">
                  <label>Confirm New Password</label>
                  <div>
                    <input
                      type="text"
                      value={passwordData.confirmPassword}
                      onChange={handleChange}
                      name="confirmPassword"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-submit me-2"
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordUpdate;

import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import config from "../../Config";
import { AuthContext } from "./AuthContext";
import { toast } from "react-toastify";

const StoreContext = createContext();

const StoreProvider = ({ children }) => {
  const [stores, setStores] = useState([]);
  const token = localStorage.getItem("token");
  const { logout } = useContext(AuthContext);
  const getStores = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/stores`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        setStores(res.data.data);
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        
      }
    }
  };

  const createStore = async (data) => {
    try {
      const res = await axios.post(`${config.BASE_URL}/store`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        toast.success("Store Created!");
        getStores();
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  const updateStore = async (id, data) => {
    try {
      const res = await axios.put(
        `${config.BASE_URL}/store/${id}`,
        data,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (res.status === 200) {
        toast.success("Store Updated Succesfuly!");
        getStores();
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  const deleteStore = async (id) => {
    try {
      const res = await axios.delete(
        `${config.BASE_URL}/store/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (res.status === 200) {
        toast.success("Store deleted succesfully!");
        getStores();
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  useEffect(() => {
    getStores();
  }, []);
  return (
    <StoreContext.Provider
      value={{ getStores, stores, createStore, updateStore, deleteStore }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export { StoreProvider, StoreContext };

import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import config from "../../Config";
import { AuthContext } from "./AuthContext";
import { toast } from "react-toastify";

const PurchaseContext = createContext();

const PurchaseProvider = ({ children }) => {
  const [purchases, setPurchases] = useState([]);
  const { logout } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const getPurchases = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/get-purchase`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        setPurchases(res.data.data);
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        
      }
    }
  };

  const createPurchase = async (data) => {
    try {
      const res = await axios.post(`${config.BASE_URL}/add-purchases`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        toast.success("Purchase has been Created!");
        getPurchases();
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  const updatePurchase = async (id, data) => {
    try {
      const res = await axios.put(
        `${config.BASE_URL}/update-purchases/${id}`,
        data,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (res.status === 200) {
        toast.success("Purchase Updated Succesfully!");
        getPurchases();
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  const deletePurchase = async (id) => {
    try {
      const res = await axios.delete(
        `${config.BASE_URL}/delete-purchases/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (res.status === 200) {
        toast.success("Purchase Deleted Succesfully!");
        getPurchases();
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  useEffect(() => {
    getPurchases();
  }, []);
  return (
    <PurchaseContext.Provider
      value={{
        getPurchases,
        purchases,
        createPurchase,
        updatePurchase,
        deletePurchase,
      }}
    >
      {children}
    </PurchaseContext.Provider>
  );
};

export { PurchaseProvider, PurchaseContext };

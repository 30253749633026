import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { IoArrowBackSharp } from "react-icons/io5";

const SimpleInterest = () => {
  const [value, setValue] = useState({
    type: "simple",
    frequency: 365,
    amount: 0,
    rate: 0,
    pUnit: 365,
    term: 1,
  });
  const [result, setResult] = useState(0);
  const [visible, setVisible] = useState({
    interest: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name !== "amount" && name !== "term" && name !== "term") {
      setValue((prevdata) => ({
        ...prevdata,
        [name]: value,
      }));
    } else {
      if (/^\d*$/.test(value)) {
        setValue((prevdata) => ({
          ...prevdata,
          [name]: value,
        }));
      }
    }
  };
  const check = () => {
    if (Number(value.interest) > 24 || Number(value.interest) < 4) {
      setVisible((prevdata) => ({
        ...prevdata,
        interest: true,
      }));
    } else {
      setVisible((prevdata) => ({
        ...prevdata,
        interest: false,
      }));
    }
  };

  const calculate = () => {
    check();
    let time;
    let interest;
    let totalAmount;
    let base;
    let power;
    if (value.type === "simple") {
      time = Number(value.term) / Number(value.pUnit);
      interest = Number(
        (Number(value.amount) * Number(value.rate) * time) / 100
      );
      setResult(interest);
    } else {
      time = Number(value.term) / Number(value.pUnit);
      // console.log("Time", time);
      base = 1 + Number(value.rate) / (100 * Number(value.frequency));
      // console.log("base", base)

      power = Number(value.frequency) * time;

      totalAmount = Number(value.amount) * Math.pow(base, power);

      interest = totalAmount - Number(value.amount);
      console.log(value.frequency);
      setResult(interest);
    }
  };
  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="d-flex align-items-center mb-5 gap-4">
          <IoArrowBackSharp
            onClick={goBack}
            style={{ fontSize: "1.5rem", cursor: "pointer" }}
          />
          <h1>Simple Interest Calculator</h1>
        </div>
        <Row className="align-items-center">
          <Col className="col-">
            <Row>
              <Col>
                <Form>
                  <Form.Group className="mb-4">
                    <Form.Label>Interest type </Form.Label>
                    <Form.Select
                      type="text"
                      name="type"
                      onChange={handleChange}
                      value={value.type}
                    >
                      <option value={"simple"}>Simple Interest</option>
                      <option value={"compound"}>Compound Interest</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label>Principal Amount</Form.Label>
                    <Form.Control
                      type="text"
                      name="amount"
                      onChange={handleChange}
                      value={value.amount}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label>Total term period ( Optional )</Form.Label>
                    <Form.Control
                      type="text"
                      name="term"
                      onChange={handleChange}
                      value={value.term}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col>
                <Form>
                  {value.type === "compound" ? (
                    <Form.Group className="mb-4">
                      <Form.Label>Compound Frequency</Form.Label>
                      <Form.Select
                        type="text"
                        name="frequency"
                        onChange={handleChange}
                        value={value.frequency}
                      >
                        <option value={365}>Daily</option>
                        <option value={52}>Weekly</option>
                        <option value={12}>Monthly</option>
                        <option value={4}>Quaterly</option>
                        <option value={2}>Half-yearly</option>
                        <option value={1}>Yearly</option>
                      </Form.Select>
                    </Form.Group>
                  ) : (
                    <></>
                  )}
                  <Form.Group className="mb-4">
                    <Form.Label>Period Unit</Form.Label>
                    <Form.Select
                      type="text"
                      name="pUnit"
                      onChange={handleChange}
                      value={value.pUnit}
                    >
                      <option value={365}>Days</option>
                      <option value={52}>Weeks</option>
                      <option value={12}>Months</option>
                      <option value={4}>Quater</option>
                      <option value={1}>Year</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label>Annual rate ( Optional )</Form.Label>
                    <Form.Control
                      type="text"
                      name="rate"
                      onChange={handleChange}
                      value={value.rate}
                      maxLength={4}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Button className="w-100" onClick={calculate}>
              {" "}
              Calculate{" "}
            </Button>
          </Col>
          <Col className=" d-flex justify-content-center">
            <div className="p-5 border rounded border-warning shadow ">
              <p className="mb-0" style={{ color: "gray", fontSize: "1.2rem" }}>
                Interest Earned
              </p>{" "}
              <h2 className="mb-3">
                <strong>
                  {"₹ "}
                  {result.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                  })}
                </strong>
              </h2>
              <p className="mb-0" style={{ color: "gray", fontSize: "1.2rem" }}>
                Principal Amount
              </p>{" "}
              <h4 className="mb-3">
                <strong>
                  {"₹ "}
                  {value.amount.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                  })}
                </strong>
              </h4>
              <p className="mb-0" style={{ color: "gray", fontSize: "1.2rem" }}>
                Total Value (Principal + Interest)
              </p>{" "}
              <h4 className="mb-3">
                <strong>
                  {"₹ "}
                  {(Number(value.amount) + Number(result)).toLocaleString(
                    "en-IN",
                    {
                      maximumFractionDigits: 2,
                    }
                  )}
                </strong>
              </h4>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SimpleInterest;

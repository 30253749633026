import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import config from "../../Config";
import { AuthContext } from "./AuthContext";
import { toast } from "react-toastify";

const CategoryContext = createContext();

const CategoryProvider = ({ children }) => {
  const { logout } = useContext(AuthContext);
  const [category, setCategory] = useState([]);
  const token = localStorage.getItem("token");
  const getCategory = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/getall-category`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        setCategory(res.data.data);
      }
    } catch (error) {
      if (error.response.status === 401) logout();
    }
  };

  const createCategory = async (data) => {
    try {
      const res = await axios.post(`${config.BASE_URL}/add-category`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        toast.success("New Category Created");
        getCategory();
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  const updateCategory = async (id, data) => {
    try {
      const res = await axios.put(
        `${config.BASE_URL}/update-category/${id}`,
        data,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (res.status === 200) {
        toast.success("Category Updated Succesfully!");
        getCategory();
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  const deleteCategory = async (id) => {
    try {
      const res = await axios.delete(
        `${config.BASE_URL}/delete-category/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (res.status === 200) {
        toast.success("Category deleted succesfully!");
        getCategory();
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  useEffect(() => {
    getCategory();
  }, []);
  return (
    <CategoryContext.Provider
      value={{
        getCategory,
        category,
        createCategory,
        updateCategory,
        deleteCategory,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};

export { CategoryProvider, CategoryContext };

import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { IoArrowBackSharp } from "react-icons/io5";

const Gst = () => {
  const [value, setValue] = useState({
    amount: 10000,
    act: "yes",
    gst: 5,
  });
  const [result, setResult] = useState({
    totalGst: 0,
    preGstAmount: 0,
  });
  const [visible, setVisible] = useState({
    interest: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "salary") {
      if (/^\d*$/.test(value)) {
        setValue((prevdata) => ({
          ...prevdata,
          [name]: value,
        }));
      }
    } else {
      setValue((prevdata) => ({
        ...prevdata,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    calculate();
  }, []);

  useEffect(() => {
    calculate();
  }, [value]);

  const calculate = () => {
    if (value.act === "yes") {
      let total = Number(value.amount) * (Number(value.gst) / 100);
      setResult({
        totalGst: total,
        preGstAmount: value.amount,
      });
    } else {
      let total =
        Number(value.amount) -
        Number(value.amount) * (100 / (100 + Number(value.gst)));
      setResult({
        totalGst: total,
        preGstAmount: Number(value.amount) - total,
      });
    }
  };
  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="d-flex align-items-center mb-5 gap-4">
          <IoArrowBackSharp
            onClick={goBack}
            style={{ fontSize: "1.5rem", cursor: "pointer" }}
          />
          <h1>GST Calculator</h1>
        </div>
        <Row className="align-items-center">
          <Col className="col-">
            <Form style={{ fontSize: "1.2rem" }}>
              <Form.Group className="mb-4">
                <div className="d-flex gap-5">
                  <Form.Check
                    type="radio"
                    name="act"
                    value="yes"
                    label="GST Exclusive"
                    checked={value.act === "yes"}
                    onChange={handleChange}
                  />
                  <Form.Check
                    type="radio"
                    name="act"
                    value="no"
                    label="GST Inclusive"
                    checked={value.act === "no"}
                    onChange={handleChange}
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Enter Amount</Form.Label>
                <Form.Control
                  type="text"
                  name="amount"
                  value={value.amount}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Select GST %</Form.Label>
                <Form.Select
                  type="text"
                  name="gst"
                  value={value.gst}
                  onChange={handleChange}
                >
                  <option value={5}>5 %</option>
                  <option value={12}>12 %</option>
                  <option value={18}>18 %</option>
                  <option value={28}>28 %</option>
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>
          <Col className=" d-flex justify-content-center">
            <div className="p-5 border rounded border-warning shadow text-center">
              <p className="mb-0" style={{ color: "gray", fontSize: "1.2rem" }}>
                Total GST
              </p>{" "}
              <h2 className="mb-3">
                <strong>
                  {"₹ "}
                  {result.totalGst.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                  })}
                </strong>
              </h2>
              <p className="mb-0" style={{ color: "gray", fontSize: "1.2rem" }}>
                Pre GST Amount
              </p>{" "}
              <h2 className="mb-3">
                <strong>
                  {"₹ "}
                  {result.preGstAmount.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                  })}
                </strong>
              </h2>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Gst;

import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import config from "../../Config";
import { AuthContext } from "../Context/AuthContext";
import { toast } from "react-toastify";

const GeneralSettings = () => {
  const [preview, setPreview] = useState({
    favIcon: null,
    logo: null,
  });
  const [provider, setProvider] = useState("gmail");
  const [smtpData, setSmtpData] = useState({
    smtpHost: "",
    smtpPort: "",
    smtpUsername: "",
    smtpPassword: "",
    useGmail: true,
    fromEmail: "",
    useTLS: false,
  });
  const [settingsData, setSettingsData] = useState({
    applicationName: "",
    description: "",
    copyrightDetails: "",
    logo: null,
    favIcon: null,
  });
  const token = localStorage.getItem("token");
  const { logout } = useContext(AuthContext);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "logo" || name === "favIcon") {
      const file = files[0];

      if (file) {
        setSettingsData((prevdata) => ({
          ...prevdata,
          [name]: file,
        }));
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview((prevdata) => ({
            ...prevdata,
            [name]: reader.result,
          }));
        };
        reader.readAsDataURL(file);
      }
    } else {
      setSettingsData((prevdata) => ({
        ...prevdata,
        [name]: value,
      }));
    }
  };

  const fetchData = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/all-settings`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        setSettingsData(res.data.data);
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("logo", settingsData.logo);
      formData.append("favIcon", settingsData.favIcon);
      formData.append(
        "applicationName",
        JSON.stringify(settingsData.applicationName)
      );
      formData.append("description", JSON.stringify(settingsData.description));
      formData.append(
        "copyrightDetails",
        JSON.stringify(settingsData.copyrightDetails)
      );

      const res = await axios.put(`${config.BASE_URL}/Settings`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        toast.success("Settings Updated!");
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  const handleSmtpChange = (e) => {
    const { name, value } = e.target;
    if (name === "smtpPort") {
      if (/^\d*$/.test(value)) {
        setSmtpData((prevdata) => ({
          ...prevdata,
          [name]: value,
        }));
      }
    } else {
      setSmtpData((prevdata) => ({
        ...prevdata,
        [name]: value,
      }));
    }
  };

  const handleSmtpUpdate = async () => {
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (provider === "gmail") {
        if (
          smtpData.smtpUsername !== "" &&
          emailRegex.test(smtpData.smtpUsername) &&
          smtpData.smtpPassword
        ) {
          const res = await axios.post(
            `${config.BASE_URL}/smtp-config`,
            smtpData,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          if (res.status === 200 || res.status === 201) {
            toast.success("SMTP added successfully!");
            setSmtpData({
              smtpHost: "",
              smtpPort: "",
              smtpUsername: "",
              smtpPassword: "",
              useGmail: false,
              fromEmail: "",
              useTLS: true,
            });
          }
        } else {
          toast.warning("Enter Proper mail and Password!");
        }
      } else {
        if (
          smtpData.smtpUsername !== "" &&
          emailRegex.test(smtpData.smtpUsername) &&
          smtpData.fromEmail !== "" &&
          smtpData.smtpHost !== "" &&
          smtpData.smtpPort !== "" &&
          emailRegex.test(smtpData.fromEmail) &&
          smtpData.smtpPassword
        ) {
          const res = await axios.post(
            `${config.BASE_URL}/smtp-config`,
            smtpData,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          if (res.status === 200 || res.status === 201) {
            toast.success("SMTP added successfully!");
            setSmtpData({
              smtpHost: "",
              smtpPort: "",
              smtpUsername: "",
              smtpPassword: "",
              useGmail: false,
              fromEmail: "",
              useTLS: true,
            });
          }
        } else {
          toast.warning("Enter Proper mail and other details!");
        }
      }
    } catch (error) {}
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Configuration Setting</h4>
            <h6>Manage General Setting</h6>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12">
                <div className="form-group">
                  <label>Logo</label>
                  <div>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleChange}
                      name="logo"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 d-flex align-items-center">
                {preview.logo ? (
                  <img
                    src={preview.logo}
                    style={{ maxHeight: "200px", maxWidth: "200px" }}
                  />
                ) : (
                  "Upload Image For Preview"
                )}
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="form-group">
                  <label>FavIcon</label>
                  <div>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleChange}
                      name="favIcon"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 d-flex align-items-center">
                {preview.favIcon ? (
                  <img
                    src={preview.favIcon}
                    style={{ maxHeight: "200px", maxWidth: "200px" }}
                  />
                ) : (
                  "Upload Image For Preview"
                )}
              </div>
              <div className="row">
                <div className="col-lg-12 d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-submit me-2"
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="form-group">
                  <label>Choose your e-mail Provider</label>
                  <div className="d-flex gap-3">
                    <div className="d-flex align-items-center gap-2">
                      <label className="mb-0">Custom</label>
                      <input
                        checked={provider === "custom"}
                        type="radio"
                        onChange={() => {
                          setProvider("custom");
                          setSmtpData((prevdata) => ({
                            ...prevdata,
                            useGmail: false,
                          }));
                        }}
                        name="provider"
                      />
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <label className="mb-0">Gmail</label>
                      <input
                        checked={provider === "gmail"}
                        type="radio"
                        onChange={() => {
                          setProvider("gmail");
                          setSmtpData((prevdata) => ({
                            ...prevdata,
                            useGmail: true,
                          }));
                        }}
                        name="provider"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="form-group">
                  <label>
                    {provider === "gmail" ? "Gmail I'd" : "SMTP UserName"}
                  </label>
                  <div>
                    <input
                      type="email"
                      value={smtpData.smtpUsername}
                      onChange={handleSmtpChange}
                      name="smtpUsername"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="form-group">
                  <label>Password</label>
                  <div>
                    <input
                      type="text"
                      value={smtpData.smtpPassword}
                      onChange={handleSmtpChange}
                      name="smtpPassword"
                    />
                  </div>
                </div>
              </div>
              {provider === "custom" && (
                <>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="form-group">
                      <label>SMTP Host</label>
                      <div>
                        <input
                          type="text"
                          value={smtpData.smtpHost}
                          onChange={handleSmtpChange}
                          name="smtpHost"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="form-group">
                      <label>SMTP Port</label>
                      <div>
                        <input
                          type="text"
                          value={smtpData.smtpPort}
                          onChange={handleSmtpChange}
                          name="smtpPort"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="form-group">
                      <label>From Email</label>
                      <div>
                        <input
                          type="text"
                          value={smtpData.fromEmail}
                          onChange={handleSmtpChange}
                          name="fromEmail"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-1">
                    <div className="form-group">
                      <label className="">Use TLS</label>

                      <input
                        type="checkbox"
                        checked={smtpData.useTLS}
                        onChange={() => {
                          setSmtpData((prevdata) => ({
                            ...prevdata,
                            useTLS: !smtpData.useTLS,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="row">
                <div className="col-lg-12 d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-submit me-2"
                    onClick={handleSmtpUpdate}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralSettings;

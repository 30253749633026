import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { IoArrowBackSharp } from "react-icons/io5";

const LoanTenure = () => {
  const [value, setValue] = useState({
    loanAmount: 100000,
    emi: 0,
    rate: 5,
  });
  const [result, setResult] = useState({
    invested: 0,
    total: 0,
  });
  const [visible, setVisible] = useState({
    loanAmount: false,
    rate: false,
    emi: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "loanAmount" || name === "emi") {
      if (/^\d*$/.test(value)) {
        setValue((prevdata) => ({
          ...prevdata,
          [name]: value,
        }));
      }
    } else if (/^\d*\.?\d*$/.test(value)) {
      setValue((prevdata) => ({
        ...prevdata,
        [name]: value,
      }));
    }
  };
  const check = () => {
    if (Number(value.loanAmount) > 20000000) {
      setVisible((prevdata) => ({
        ...prevdata,
        loanAmount: true,
      }));
    } else {
      setVisible((prevdata) => ({
        ...prevdata,
        loanAmount: false,
      }));
    }
    if (Number(value.rate) > 20 || Number(value.rate) === 0) {
      setVisible((prevdata) => ({
        ...prevdata,
        rate: true,
      }));
    } else {
      setVisible((prevdata) => ({
        ...prevdata,
        rate: false,
      }));
    }
  };

  useEffect(() => {
    calculate();
  }, []);

  useEffect(() => {
    calculate();
  }, [value]);

  const calculate = () => {
    check();
    const rate2 = Number(value.rate) / 1200;
    const rateterm = Math.pow(1 + rate2, 360);
    let emiMin = (Number(value.loanAmount) * rate2 * rateterm) / (rateterm - 1);
    if (Number(value.emi) < emiMin) {
      setValue((prevdata) => ({
        ...prevdata,
        emi: emiMin,
      }));
    }
    const denominator = Math.log(1 + rate2);

    const numerator =
      Math.log(Number(value.emi)) -
      Math.log(Number(value.emi) - Number(value.loanAmount) * rate2);
    const result = numerator / denominator;
    setResult(result);
  };
  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="d-flex align-items-center mb-5 gap-4">
          <IoArrowBackSharp
            onClick={goBack}
            style={{ fontSize: "1.5rem", cursor: "pointer" }}
          />
          <h1>Loan Tenure Calculator</h1>
        </div>
        <Row className="align-items-center">
          <Col className="col-">
            <Form>
              <Form.Group className="mb-4">
                <Form.Label>Loan Amount</Form.Label>
                <Form.Control
                  type="text"
                  name="loanAmount"
                  value={value.loanAmount}
                  onChange={handleChange}
                />
                <Row className="align-items-center">
                  <Col xs="auto">
                    <Form.Label>1L</Form.Label>
                  </Col>
                  <Col>
                    <Form.Range
                      min={100000}
                      max={20000000}
                      value={value.loanAmount}
                      name="loanAmount"
                      onChange={handleChange}
                    />
                  </Col>
                  <Col xs="auto">
                    <Form.Label>2Cr</Form.Label>
                  </Col>
                </Row>
                {visible.loanAmount && (
                  <Form.Text className="text-danger">
                    Error: Please enter amount between 1 Lakh and 2 Crores
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>EMI</Form.Label>
                <Form.Control
                  type="text"
                  name="emi"
                  value={value.emi}
                  onChange={handleChange}
                />
                <Row className="align-items-center">
                  <Col xs="auto">
                    <Form.Label>0</Form.Label>
                  </Col>
                  <Col>
                    <Form.Range
                      min={0}
                      max={100000}
                      value={value.emi}
                      name="emi"
                      onChange={handleChange}
                    />
                  </Col>
                  <Col xs="auto">
                    <Form.Label>1L</Form.Label>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Interest Rate</Form.Label>
                <Form.Control
                  type="text"
                  name="rate"
                  value={value.rate}
                  onChange={handleChange}
                />
                <Row className="align-items-center">
                  <Col xs="auto">
                    <Form.Label>0</Form.Label>
                  </Col>
                  <Col>
                    <Form.Range
                      min={0.0}
                      max={20.0}
                      value={value.rate}
                      name="rate"
                      onChange={handleChange}
                      step={0.1}
                    />
                  </Col>
                  <Col xs="auto">
                    <Form.Label>20</Form.Label>
                  </Col>
                </Row>
                {visible.age && (
                  <Form.Text className="text-danger">
                    Error: Please enter rate between 0 % and 20 %
                  </Form.Text>
                )}
              </Form.Group>
            </Form>
          </Col>
          <Col className=" d-flex justify-content-center">
            <div className="p-5 border rounded border-warning shadow text-center">
              <p className="mb-0" style={{ color: "gray", fontSize: "1.2rem" }}>
                Loan Tenure
              </p>{" "}
              <h2 className="mb-3">
                <strong>
                  {Math.floor(result)} {"Months"}
                </strong>
              </h2>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LoanTenure;

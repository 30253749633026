import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import config from "../../Config";
import { AuthContext } from "./AuthContext";
import { toast } from "react-toastify";

const VendorContext = createContext();

const VendorProvider = ({ children }) => {
  const [vendors, setVendors] = useState([]);
  const token = localStorage.getItem("token");
  const { logout } = useContext(AuthContext);
  const getVendors = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/getallvendors`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        setVendors(res.data.data);
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        
      }
    }
  };

  const createVendor = async (data) => {
    try {
      const res = await axios.post(`${config.BASE_URL}/add-vendors`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        toast.success("Vendor Created!");
        getVendors();
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  const updateVendor = async (id, data) => {
    try {
      const res = await axios.put(
        `${config.BASE_URL}/update-vendors/${id}`,
        data,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (res.status === 200) {
        toast.success("Vendor Updated Succesfuly!");
        getVendors();
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  const deleteVendor = async (id) => {
    try {
      const res = await axios.delete(
        `${config.BASE_URL}/delete-vendors/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (res.status === 200) {
        toast.success("Vendor deleted succesfully!");
        getVendors();
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  useEffect(() => {
    getVendors();
  }, []);
  return (
    <VendorContext.Provider
      value={{ getVendors, vendors, createVendor, updateVendor, deleteVendor }}
    >
      {children}
    </VendorContext.Provider>
  );
};

export { VendorProvider, VendorContext };

import React, { useContext, useEffect, useState } from "react";
import config from "../../Config";
import axios from "axios";
import { AuthContext } from "../Context/AuthContext";
import { toast } from "react-toastify";
import { FaStar } from "react-icons/fa"; // Importing the star icon from react-icons

const Feedback = () => {
  const { logout, user } = useContext(AuthContext);
  const [feedback, setFeedback] = useState({
    userId: "",
    feedbackText: "",
    rating: 1,
  });
  const [hover, setHover] = useState(null); // State for star hover effect
  const token = localStorage.getItem("token");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedback((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (user && user.userId) {
      setFeedback({
        userId: user.userId,
        feedbackText: "",
        rating: 1,
      });
    }
  }, [user]);

  const handleCreate = async () => {
    try {
      if (feedback.feedbackText !== "") {
        const res = await axios.post(`${config.BASE_URL}/feedback`, feedback, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (res.status === 200) {
          toast.success("Feedback Sent!");
          setFeedback({
            userId: user.userId,
            feedbackText: "",
            rating: 1,
          });
        }
      } else {
        toast.warning("Enter Some Feedback!!");
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  // Function to handle star click
  const handleRating = (ratingValue) => {
    setFeedback((prevdata) => ({
      ...prevdata,
      rating: ratingValue,
    }));
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Feedback</h4>
            <h6>Give your suggestions</h6>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="col-lg-6 col-sm-6 col-12">
              {/* Feedback Text Area */}
              <div className="form-group">
                <label>Feedback</label>
                <textarea
                  type="text"
                  name="feedbackText"
                  value={feedback.feedbackText}
                  placeholder="Enter your suggestions"
                  onChange={handleChange}
                />
              </div>

              {/* Star Rating Section */}
              <div className="form-group">
                <label>Rating</label>
                <div className="star-rating d-flex gap-2">
                  {[...Array(5)].map((star, index) => {
                    const ratingValue = index + 1;
                    return (
                      <label key={index}>
                        <input
                          type="radio"
                          name="rating"
                          value={ratingValue}
                          onClick={() => handleRating(ratingValue)}
                          style={{ display: "none" }}
                        />
                        <FaStar
                          className="star"
                          color={
                            ratingValue <= (hover || feedback.rating)
                              ? "#ffc107"
                              : "#e4e5e9"
                          }
                          size={30}
                          onMouseEnter={() => setHover(ratingValue)}
                          onMouseLeave={() => setHover(null)}
                          style={{ cursor: "pointer" }}
                        />
                      </label>
                    );
                  })}
                </div>
              </div>

              {/* Submit Button */}
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  onClick={handleCreate}
                  className="btn btn-submit me-2"
                  style={{ width: "fit-content" }}
                >
                  Send Feedback
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;

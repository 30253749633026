import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { IoArrowBackSharp } from "react-icons/io5";

const NPS = () => {
  const [value, setValue] = useState({
    presentAge: 18,
    retireAge: 40,
    contri: 10000,
    interest: 12,
  });
  const [result, setResult] = useState({
    invested:0,
    total:0,
  });
  const [visible, setVisible] = useState({
    age: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "monthlyIncome" ||
      name === "contri" ||
      name === "interest"
    ) {
      if (/^\d*$/.test(value)) {
        setValue((prevdata) => ({
          ...prevdata,
          [name]: value,
        }));
      }
    } else {
      setValue((prevdata) => ({
        ...prevdata,
        [name]: value,
      }));
    }
  };
  const check = () => {
    if (Number(value.presentAge) > Number(value.retireAge)) {
      setVisible((prevdata) => ({
        ...prevdata,
        age: true,
      }));
    } else {
      setVisible((prevdata) => ({
        ...prevdata,
        age: false,
      }));
    }
  };

  useEffect(() => {
    calculate();
  }, []);

  useEffect(() => {
    calculate();
  }, [value]);

  const calculate = () => {
    check();
    let time = Number(value.retireAge) - Number(value.presentAge);
    let term = (time)*12;
    let totalInvestment=value.contri*term;
    let cumulative = 0;
    let rate = value.interest/100;
    for (let i = 0; i < term; ++i) {
        cumulative = (value.contri + cumulative) * (1 + rate/12)
      }

      setResult({
        invested:totalInvestment,
        total:cumulative
      })
   
  };
  const goBack = () => {
    window.history.back();
  };


  return (
    <div className="page-wrapper">
      <div className="content">
      <div className="d-flex align-items-center mb-5 gap-4">
      <IoArrowBackSharp onClick={goBack} style={{fontSize:"1.5rem",cursor:"pointer"}} />
        <h1>NPS Calculator</h1>
        </div>
        <Row className="align-items-center">
          <Col className="col-">
            <Form>
              <Form.Group className="mb-4">
                <Form.Label>Present Age in Years</Form.Label>
                <Form.Control type="text" value={value.presentAge} readOnly />
                <Row className="align-items-center">
                  <Col xs="auto">
                    <Form.Label>18</Form.Label>
                  </Col>
                  <Col>
                    <Form.Range
                      min={18}
                      max={100}
                      value={value.presentAge}
                      name="presentAge"
                      onChange={handleChange}
                    />
                  </Col>
                  <Col xs="auto">
                    <Form.Label>100</Form.Label>
                  </Col>
                </Row>
                {visible.age && (
                  <Form.Text className="text-danger">
                    Error: Present Age can't be greater than Retirement Age
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Retirement Age in Years</Form.Label>
                <Form.Control type="text" value={value.retireAge} readOnly />
                <Row className="align-items-center">
                  <Col xs="auto">
                    <Form.Label>18</Form.Label>
                  </Col>
                  <Col>
                    <Form.Range
                      min={18}
                      max={110}
                      value={value.retireAge}
                      name="retireAge"
                      onChange={handleChange}
                    />
                  </Col>
                  <Col xs="auto">
                    <Form.Label>110</Form.Label>
                  </Col>
                </Row>
                {visible.age && (
                  <Form.Text className="text-danger">
                    Error: Present Age can't be greater than Retirement Age
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>
                Contribution towards NPS per month
                </Form.Label>
                <Form.Control
                  type="text"
                  name="contri"
                  onChange={handleChange}
                  value={value.contri}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>
                Expected rate of return on NPS investment
                </Form.Label>
                <Form.Control
                  type="text"
                  name="interest"
                  onChange={handleChange}
                  value={value.interest}
                />
              </Form.Group>
              
            </Form>
          </Col>
          <Col className=" d-flex justify-content-center">
            <div className="p-5 border rounded border-warning shadow text-center">
            <p className="mb-0" style={{ color: "gray", fontSize: "1.2rem" }}>
              Total amount Invested
              </p>{" "}
              <h2 className="mb-3">
                <strong>
                  {" "}
                  ₹{" "}
                  {!visible.age && !visible.rate
                    ? result.invested.toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                      })
                    : "0"}{" "}
                </strong>
              </h2>
              <p className="mb-0" style={{ color: "gray", fontSize: "1.2rem" }}>
              Total amount after retirement
              </p>{" "}
              <h2>
                <strong>
                  {" "}
                  ₹{" "}
                  {!visible.age && !visible.rate
                    ? result.total.toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                      })
                    : "0"}{" "}
                </strong>
              </h2>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NPS;

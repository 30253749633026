import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import config from "../../Config";
import DataTable from "react-data-table-component";
import { VendorContext } from "../Context/VendorContext";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";
import { AuthContext } from "../Context/AuthContext";
import { toast } from "react-toastify";

const PaymentReport = () => {
  const [paymentData, setPaymentData] = useState([]);
  const token = localStorage.getItem('token');
  const {logout} = useContext(AuthContext);
  const [filterShow, setFilterShow] = useState(false);
  const [filterInputs, setFilterInputs] = useState({
    startDate: "",
    endDate: "",
    vendor: "",
  });
  const { vendors } = useContext(VendorContext);
  const [disable,setDisable] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterInputs((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };

  const filterSearch = () => {
    const startDate = filterInputs.startDate
      ? new Date(filterInputs.startDate)
      : null;
    const endDate = filterInputs.endDate
      ? new Date(filterInputs.endDate)
      : null;
    if (startDate && endDate && filterInputs.vendor !== "") {
      return paymentData.filter((item) => {
        const itemDate = new Date(item.paymentDate);

        return (
          itemDate >= startDate &&
          itemDate <= endDate &&
          item.vendor === filterInputs.vendor
        );
      });
    } else if (startDate && endDate) {
      return paymentData.filter((item) => {
        const itemDate = new Date(item.paymentDate);

        return itemDate >= startDate && itemDate <= endDate;
      });
    } else if (filterInputs.vendor !== "") {
      return paymentData.filter((item) => {
        return item.vendor === filterInputs.vendor;
      });
    } else {
      return paymentData;
    }
  };

  const fetchPayment = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/getall-payment`,{
        headers: {'Authorization': `Bearer ${token}`},
      });
      if (res.status === 200) {
        setPaymentData(res.data.data);
      }
    } catch (error) {
      if(error.response.status === 401) {logout(); toast.error("Your token has expired please login again!");}
    }
  };

  useEffect(() => {
    fetchPayment();
  }, []);

  const tableHead = [
    {
      name: 'SNo',
      selector: (row, index) => index + 1, 
      sortable: false,
      width: '70px',
    },
    {
      name: "Purchase ID",
      selector: (row) => row.purchaseOrder,
      sortable: true,
    },
    {
      name: "Vendor",
      selector: (row) => row.vendorId.vendorsname,
      sortable: true,
    },
    {
      name: "Payment Date",
      selector: (row) => row.paymentDate && row.paymentDate.slice(0, 10),
      sortable: true,
    },
    {
      name: "Amount Paid",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Payment Type",
      selector: (row) => row.paymentType,
      sortable: true,
    },
  ];

  const generatePDF = () => {
    setDisable(true);
    const doc = new jsPDF();
    const columns = ["Payment Id","Purchse Order", "Vendor", "Payment Date", "Amount Paid","Payment Type"];

    const rows = paymentData.map((data) => [
      data._id,
      data.purchaseOrder,
      data.vendorId.vendorsname,
     data.paymentDate && data.paymentDate.slice(0, 10),
      data.amount,
      data.paymentType,
    ]);

    doc.text("Payment Report", 14, 15);
    doc.autoTable({
      head: [columns],
      body: rows,
      startY: 20,
      theme: "grid",
    });
    doc.save("payment_report.pdf");
    setDisable(false);
    toast.success("PDF downloaded!");
  };

  const handleDownloadExcel = async () => {
    if (paymentData.length !== 0) {
      setDisable(true);
      const visibleData = paymentData.map((item) => ({
        Payment_ID: item._id,
        Purchase_Order:item.purchaseOrder,
        Vendor: item.vendorId.vendorsname,
        Payment_Date: item.paymentDate && item.paymentDate.slice(0, 10),
        Amount_Paid: item.amount,
        Payment_Type:item.paymentType
      }));
      try {
        const ws = XLSX.utils.json_to_sheet(visibleData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `payment_report.xlsx`);
      } catch (error) {
        console.error("Error generating Excel file:", error);
      }
      setDisable(false);
      toast.success("XLSX Downloaded!")
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Payment Report </h4>
            <h6>Manage your purchases</h6>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-path">
                  <button
                    className={`btn btn-filter ${filterShow ? "setclose" : ""}`}
                    onClick={() => setFilterShow(!filterShow)}
                    id="filter_search"
                  >
                    <img src="/img/icons/filter.svg" alt="img" />
                    <span>
                      <img src="/img/icons/closes.svg" alt="img" />
                    </span>
                  </button>
                </div>
                <div className="search-input">
                  <button className="btn btn-searchset">
                    <img src="/img/icons/search-white.svg" alt="img" />
                  </button>
                </div>
              </div>
              <div className="wordset">
                <ul>
                  <li>
                    <button type="button"
                    onClick={generatePDF}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="pdf"
                    >
                      <img src="/img/icons/pdf.svg" alt="img" />
                    </button>
                  </li>
                  <li>
                    <button type="button"
                    onClick={handleDownloadExcel}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="excel"
                    >
                      <img src="/img/icons/excel.svg" alt="img" />
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="card"
              id="filter_inputs"
              style={{ display: filterShow ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <div className="input-groupicon">
                        <input
                          type="date"
                          placeholder="From Date"
                          className="datetimepicker"
                          name="startDate"
                          onChange={handleChange}
                          value={filterInputs.startDate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <div className="input-groupicon">
                        <input
                          type="date"
                          placeholder="To Date"
                          className="datetimepicker"
                          name="endDate"
                          onChange={handleChange}
                          value={filterInputs.endDate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <select
                        className="select"
                        onChange={handleChange}
                        name="vendor"
                        value={filterInputs.vendor}
                      >
                        <option value={""}>All Vendors</option>
                        {vendors &&
                          vendors.map((item) => (
                            <option key={item._id} value={item._id}>
                              {item.vendorsname}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                    <div className="form-group">
                      <button
                        onClick={() =>
                          setFilterInputs({
                            startDate: "",
                            endDate: "",
                            vendor: "",
                          })
                        }
                        className="btn btn-filters ms-auto"
                      >
                       <img src="/img/icons/closes.svg" alt="img" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <DataTable columns={tableHead} data={filterSearch()} pagination />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentReport;

import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import config from "../../Config";
import { tab } from "@testing-library/user-event/dist/tab";
import { AuthContext } from "../Context/AuthContext";
import { toast } from "react-toastify";

const OutstandingPayment = () => {
  const [data, setData] = useState([]);
  const token = localStorage.getItem("token");
  const { logout } = useContext(AuthContext);

  const tableHead = [
    {
      name: "Purchase ID",
      selector: (row) => row.purchaseId,
      sortable: true,
    },
    {
      name: "Total Amount",
      selector: (row) => row.totalPrice,
      sortable: true,
    },
    // {
    //   name: "Amount Paid",
    //   selector: (row) => row.amount,
    //   sortable: true,
    // },
    {
      name: "OutStanding Payment",
      selector: (row) => row.outStanding,
      sortable: true,
    },
  ];

  const fetchData = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/get-outstanding`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        setData(res.data.data);
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Outstanding Payment </h4>
            <h6>Manage your purchases</h6>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <DataTable columns={tableHead} data={data} pagination />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutstandingPayment;

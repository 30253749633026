import Modal from "react-bootstrap/Modal";
import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AuthContext } from "../Context/AuthContext";
import { toast } from "react-toastify";
import axios from "axios";
import config from "../../Config";

const GST = () => {
  const [show, setShow] = useState(false);
  const [gstData, setGstData] = useState([]);
  const [editShow, setEditShow] = useState(false);
  const { permission } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const [formData, setFormData] = useState({
    gstRate: "",
    hsnCode: "",
    description: "",
  });

  // const { hsnCode } = useContext(CategoryContext);
  const tableHead = [
    {
      name: "SNo",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "70px",
    },
    {
      name: "GST Rate",
      selector: (row) => row.gstRate,
      sortable: true,
    },
    {
      name: "HSN Code",
      selector: (row) => row.hsnCode,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    ...(permission.item.edit || permission.item.delete
      ? [
          {
            name: "Action",
            selector: (row) => (
              <>
                {permission.item.edit && (
                  <a onClick={() => handleEditClick(row)} className="me-3">
                    <img src="/img/icons/edit.svg" alt="edit" />
                  </a>
                )}
                {permission.item.delete && (
                  <a
                    onClick={() => handleDelete(row._id)}
                    className="confirm-text"
                  >
                    <img src="/img/icons/delete.svg" alt="delete" />
                  </a>
                )}
              </>
            ),
            sortable: false,
          },
        ]
      : []),
  ];

  const handleDelete = async (id) => {
    const isConfirm = window.confirm(
      "Are you sure, you want to delete the Party?"
    );
    if (isConfirm) {
      try {
        const res = await axios.delete(`${config.BASE_URL}/gst/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (res.status === 200) {
          fetchGST();
          toast.success("Item deleted Successfully!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleEditClick = (data) => {
    setFormData(data);
    setEditShow(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };
  const fetchGST = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/gst`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        setGstData(res.data.data);
      }
    } catch (error) {
      toast.error("Something went Wrong");
    }
  };

  const handleCreate = async () => {
    try {
      if (
        formData.gstRate !== "" &&
        formData.hsnCode !== "" &&
        formData.description !== ""
      ) {
        const res = await axios.post(`${config.BASE_URL}/gst`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (res.status === 201 || res.status === 200) {
          fetchGST();
          toast.success("GST created successfully!");
          setFormData({
            gstRate: "",
            hsnCode: "",
            description: "",
          });
          setShow(false);
        }
      } else {
        toast.warning("Enter all Fields!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = async (id) => {
    try {
      if (
        formData.gstRate !== "" &&
        formData.hsnCode !== "" &&
        formData.description !== ""
      ) {
        const res = await axios.put(`${config.BASE_URL}/gst/${id}`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (res.status === 200) {
          fetchGST();
          toast.success("GST Updated successfully!");
          setFormData({
            gstRate: "",
            hsnCode: "",
            description: "",
          });
          setEditShow(false);
        }
      } else {
        toast.warning("Enter all Fields!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchGST();
  }, []);

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>GST List</h4>
            <h6>Manage all the GST rates and HSN code</h6>
          </div>
          {permission.item.create && (
            <>
              <div className="page-btn">
                <a onClick={() => setShow(true)} className="btn btn-added">
                  <img src="/img/icons/plus.svg" alt="img" className="me-2" />{" "}
                  Add GST
                </a>
              </div>
              <div className="page-btn-mob">
                <a
                  onClick={() => setShow(true)}
                  className="btn btn-added btn-added-mob"
                >
                  <img src="/img/icons/plus.svg" alt="img" />
                </a>
              </div>
            </>
          )}
        </div>

        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <DataTable columns={tableHead} data={gstData} pagination />
            </div>
          </div>
        </div>

        <Modal size="lg" show={show} onHide={() => setShow(false)}>
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>GST Rate (%)</label>
                    <input
                      type="number"
                      name="gstRate"
                      value={formData.gstRate}
                      placeholder="Enter GST Rate"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>HSN Code</label>
                    <input
                      type="text"
                      name="hsnCode"
                      value={formData.hsnCode}
                      placeholder="Enter HSN Code"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      type="text"
                      name="description"
                      value={formData.description}
                      placeholder="Enter Description"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-lg-12 d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={handleCreate}
                    className="btn btn-submit me-2"
                  >
                    Create
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setShow(false);
                      setFormData({
                        gstRate: "",
                        hsnCode: "",
                        description: "",
                      });
                    }}
                    className="btn btn-cancel"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal size="lg" show={editShow} onHide={() => setEditShow(false)}>
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>GST Rate</label>
                    <input
                      type="number"
                      name="gstRate"
                      value={formData.gstRate}
                      placeholder="Enter Party's name"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>HSN Code</label>
                    <input
                      type="text"
                      name="hsnCode"
                      value={formData.hsnCode}
                      placeholder="Enter Contact Person's Name"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      type="text"
                      name="description"
                      value={formData.description}
                      placeholder="Enter Phone Number"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-lg-12 d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={() => handleUpdate(formData._id)}
                    className="btn btn-submit me-2"
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setEditShow(false);
                      setFormData({
                        gstRate: "",
                        hsnCode: "",
                        description: "",
                      });
                    }}
                    className="btn btn-cancel"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default GST;

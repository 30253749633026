import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const HomeLayout = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    useEffect(()=>{
        if(!token) {
            navigate("/login");
          }
          else navigate("/dashboard");
    },[]);
    
        
    
        return (
            <>
        <div>
            <Outlet/> 
            
            </div>
            </>
    )
}

export default HomeLayout
import React, { useContext, useEffect } from "react";
import { ItemContext } from "../Context/ItemContext";
import axios from "axios";
import config from "../../Config";
import { toast } from "react-toastify";

const AllNotifications = () => {
  const { getNotifications, notifications } = useContext(ItemContext);
  const token = localStorage.getItem("token");
  const notifyReset = async () => {
    try {
      const res = await axios.put(
        `${config.BASE_URL}/notifications/mark-all-read`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (res.status === 200) {
        getNotifications();
      }
    } catch (error) {
      toast.error("Something Went Wrong!!");
    }
  };

  useEffect(() => {
    notifyReset();
  }, []);
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>All Notifications</h4>
            <h6>View your all activities</h6>
          </div>
        </div>

        <div className="activity">
          <div className="activity-box">
            <ul className="activity-list">
              {notifications &&
                notifications.map((item) => (
                  <li className="activity-content p-3 rounded-3 d-flex align-items-center">
                    <p className="name">{item.message}</p>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllNotifications;

import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { IoArrowBackSharp } from "react-icons/io5";

const PF = () => {
  const [value, setValue] = useState({
    presentAge: 15,
    monthlyIncome: 10000,
    contribution: 12,
    employerContribution: 3.67,
    retireAge: 40,
    balance: 0,
    interest: 8.1,
  });
  const [result, setResult] = useState(0);
  const [visible, setVisible] = useState({
    rate: false,
    age: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "monthlyIncome" ||
      name === "contribution" ||
      name === "balance"
    ) {
      if (/^\d*$/.test(value)) {
        setValue((prevdata) => ({
          ...prevdata,
          [name]: value,
        }));
      }
    } else {
      setValue((prevdata) => ({
        ...prevdata,
        [name]: value,
      }));
    }
  };
  const check = () => {
    if (Number(value.contribution) < 12) {
      setVisible((prevdata) => ({
        ...prevdata,
        rate: true,
      }));
    } else {
      setVisible((prevdata) => ({
        ...prevdata,
        rate: false,
      }));
    }
    if (Number(value.presentAge) > Number(value.retireAge)) {
      setVisible((prevdata) => ({
        ...prevdata,
        age: true,
      }));
    } else {
      setVisible((prevdata) => ({
        ...prevdata,
        age: false,
      }));
    }
  };

  useEffect(() => {
    calculate();
  }, []);

  useEffect(() => {
    calculate();
  }, [value]);

  const calculate = () => {
    check();
    let epfBal = Number(value.balance);
    const t = Number(value.retireAge) - Number(value.presentAge);
    const selfYearContri =
      (Number(value.monthlyIncome) * 12 * Number(value.contribution)) / 100;
    const employerYearContri =
      (Number(value.monthlyIncome) * 12 * Number(value.employerContribution)) /
      100;
    const totalYearContri = selfYearContri + employerYearContri;
    for (let i = Number(value.presentAge); i <= Number(value.retireAge); ++i) {
      epfBal = (epfBal + totalYearContri) * (1 + value.interest / 100);
    }
    setResult(epfBal);
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="d-flex align-items-center mb-5 gap-4">
      <IoArrowBackSharp onClick={goBack} style={{fontSize:"1.5rem",cursor:"pointer"}} />
        <h1 >PF Calculator</h1>
        </div>
        <Row className="align-items-center">
          <Col className="col-">
            <Form>
              <Form.Group className="mb-4">
                <Form.Label>Present Age in Years</Form.Label>
                <Form.Control type="text" value={value.presentAge} readOnly />
                <Row className="align-items-center">
                  <Col xs="auto">
                    <Form.Label>15</Form.Label>
                  </Col>
                  <Col>
                    <Form.Range
                      min={15}
                      max={58}
                      value={value.presentAge}
                      name="presentAge"
                      onChange={handleChange}
                    />
                  </Col>
                  <Col xs="auto">
                    <Form.Label>58</Form.Label>
                  </Col>
                </Row>
                {visible.age && (
                  <Form.Text className="text-danger">
                    Error: Present Age can't be greater than Retirement Age
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>
                  Your Monthly Basic Salary+DA (Max Wage Celing is ₹.15,000)
                  Optional
                </Form.Label>
                <Form.Control
                  type="text"
                  name="monthlyIncome"
                  onChange={handleChange}
                  value={value.monthlyIncome}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>
                  Your Contribution to EPF (Min : 12%) Optional
                </Form.Label>
                <Form.Control
                  type="text"
                  name="contribution"
                  onChange={handleChange}
                  value={value.contribution}
                />
                {visible.rate && (
                  <Form.Text className="text-danger">
                    Error: Rate can't be less than 12 %
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>
                  Your Employer's Contribution To EPF Optional
                </Form.Label>
                <Form.Control
                  type="text"
                  value={
                    value.employerContribution +
                    " Your Employer's Contribution To EPF Optional"
                  }
                  readOnly
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Retirement Age in Years</Form.Label>
                <Form.Control type="text" value={value.retireAge} readOnly />
                <Row className="align-items-center">
                  <Col xs="auto">
                    <Form.Label>16</Form.Label>
                  </Col>
                  <Col>
                    <Form.Range
                      min={16}
                      max={58}
                      value={value.retireAge}
                      name="retireAge"
                      onChange={handleChange}
                    />
                  </Col>
                  <Col xs="auto">
                    <Form.Label>58</Form.Label>
                  </Col>
                </Row>
                {visible.age && (
                  <Form.Text className="text-danger">
                    Error: Present Age can't be greater than Retirement Age
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Current EPF Balance ( Optional )</Form.Label>
                <Form.Control
                  type="text"
                  name="balance"
                  onChange={handleChange}
                  value={value.balance}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Current Interest Rate ( Optional )</Form.Label>
                <Form.Control
                  type="text"
                  value={value.interest + "% For FY (2022-23)"}
                  readOnly
                />
              </Form.Group>
            </Form>
          </Col>
          <Col className=" d-flex justify-content-center">
            <div className="p-5 border rounded border-warning shadow text-center">
              <p style={{ color: "gray", fontSize: "1.2rem" }}>
                EPF Fund Available After Retirement
              </p>{" "}
              <h2>
                <strong>
                  {" "}
                  ₹{" "}
                  {!visible.age && !visible.rate
                    ? result.toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                      })
                    : "0"}{" "}
                </strong>
              </h2>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PF;

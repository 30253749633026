import React from 'react';

const PaymentList = () => {
    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="page-title">
                        <h4>Payment LIST</h4>
                        <h6>Manage your payments</h6>
                    </div>
                    <div className="page-btn">
                        <a href="createexpense.html" className="btn btn-added">
                            <img src="/img/icons/plus.svg" className="me-2" alt="img" />
                            Add New Payment
                        </a>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-path">
                                    <a className="btn btn-filter" id="filter_search">
                                        <img src="/img/icons/filter.svg" alt="img" />
                                        <span><img src="/img/icons/closes.svg" alt="img" /></span>
                                    </a>
                                </div>
                                <div className="search-input">
                                    <a className="btn btn-searchset">
                                        <img src="/img/icons/search-white.svg" alt="img" />
                                    </a>
                                </div>
                            </div>
                            <div className="wordset">
                                <ul>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf">
                                            <img src="/img/icons/pdf.svg" alt="img" />
                                        </a>
                                    </li>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel">
                                            <img src="/img/icons/excel.svg" alt="img" />
                                        </a>
                                    </li>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="print">
                                            <img src="/img/icons/printer.svg" alt="img" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="card" id="filter_inputs">
                            <div className="card-body pb-0">
                                <div className="row">
                                    <div className="col-lg-2 col-sm-6 col-12">
                                        <div className="form-group">
                                            <div className="input-groupicon">
                                                <input type="text" className="datetimepicker cal-icon" placeholder="Choose Date" />
                                                <div className="addonset">
                                                    <img src="/img/icons/calendars.svg" alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-sm-6 col-12">
                                        <div className="form-group">
                                            <input type="text" placeholder="Enter Reference" />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-sm-6 col-12">
                                        <div className="form-group">
                                            <select className="select">
                                                <option>Choose Category</option>
                                                <option>Computers</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-sm-6 col-12">
                                        <div className="form-group">
                                            <select className="select">
                                                <option>Choose Status</option>
                                                <option>Complete</option>
                                                <option>Inprogress</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                                        <div className="form-group">
                                            <a className="btn btn-filters ms-auto">
                                                <img src="/img/icons/search-whites.svg" alt="img" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="table-responsive">
                            <table className="table datanew">
                                <thead>
                                    <tr>
                                        <th>
                                            <label className="checkboxs">
                                                <input type="checkbox" id="select-all" />
                                                <span className="checkmarks"></span>
                                            </label>
                                        </th>
                                        <th>Category name</th>
                                        <th>Reference</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                        <th>Amount</th>
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <label className="checkboxs">
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                            </label>
                                        </td>
                                        <td>Employee Benefits</td>
                                        <td>PT001</td>
                                        <td>19 Nov 2022</td>
                                        <td><span className="badges bg-lightgreen">Active</span></td>
                                        <td>120</td>
                                        <td>Employee Vehicle</td>
                                        <td>
                                            <a className="me-3" href="editexpense.html">
                                                <img src="/img/icons/edit.svg" alt="img" />
                                            </a>
                                            <a className="me-3 confirm-text" href="#">
                                                <img src="/img/icons/delete.svg" alt="img" />
                                            </a>
                                        </td>
                                    </tr>
                                    {/* Additional rows follow the same structure */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default PaymentList;

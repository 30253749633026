import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { IoArrowBackSharp } from "react-icons/io5";

const Hra = () => {
  const [value, setValue] = useState({
    da: 0,
    salary: 0,
    hra: 0,
    rent: 0,
    city: "yes",
  });
  const [result, setResult] = useState({
    hraTaxable: 0,
    perbsalary: 0,
    hra: 0,
    excessRent: 0,
    hraExempted: 0,
  });
  const [visible, setVisible] = useState({
    interest: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "city") {
      setValue((prevdata) => ({
        ...prevdata,
        [name]: value,
      }));
    } else {
      if (/^\d*$/.test(value)) {
        setValue((prevdata) => ({
          ...prevdata,
          [name]: value,
        }));
      }
    }
  };
  const check = () => {
    if (Number(value.interest) > 24 || Number(value.interest) < 4) {
      setVisible((prevdata) => ({
        ...prevdata,
        interest: true,
      }));
    } else {
      setVisible((prevdata) => ({
        ...prevdata,
        interest: false,
      }));
    }
  };

  useEffect(() => {
    calculate();
  }, []);

  useEffect(() => {
    calculate();
    console.log(value);
  }, [value]);

  const calculate = () => {
    check();
    let salary_part =
      value.city === "yes"
        ? 0.5 * (Number(value.salary) + Number(value.da))
        : 0.4 * (Number(value.salary) + Number(value.da));
    let excessRent =
      Number(value.rent) - 0.1 * (Number(value.salary) + Number(value.da));
    let hraExempted = Number(value.hra);
    if (excessRent > 0) {
      if (salary_part <= excessRent && salary_part <= Number(value.hra)) {
        hraExempted = salary_part;
      } else if (excessRent <= salary_part && excessRent <= Number(value.hra)) {
        hraExempted = excessRent;
      } else if (
        Number(value.hra) <= salary_part &&
        Number(value.hra) <= excessRent
      ) {
        hraExempted = Number(value.hra);
      }
    } else {
      hraExempted = 0;
      excessRent = 0;
    }

    let hraTaxable = Number(value.hra) - hraExempted;
    setResult({
      hraTaxable: hraTaxable,
      perbsalary: salary_part,
      hra: value.hra,
      excessRent: excessRent,
      hraExempted: hraExempted,
    });
  };
  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="page-wrapper">
      <div className="content">
      <div className="d-flex align-items-center mb-5 gap-4">
      <IoArrowBackSharp onClick={goBack} style={{fontSize:"1.5rem",cursor:"pointer"}} />
        <h1>Gratuity Calculator</h1>
        </div>
        <Row className="align-items-center">
          <Col className="col-">
            <Form>
              <Form.Group className="mb-4">
                <Form.Label>Basic salary received ( per annum )</Form.Label>
                <Form.Control
                  type="text"
                  name="salary"
                  value={value.salary}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>
                  Dearness Allowance (DA) received ( per annum )
                </Form.Label>
                <Form.Control
                  type="text"
                  name="da"
                  value={value.da}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>HRA received ( per annum )</Form.Label>
                <Form.Control
                  type="text"
                  name="hra"
                  value={value.hra}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Total Rent Paid ( per annum )</Form.Label>
                <Form.Control
                  type="text"
                  name="rent"
                  value={value.rent}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  {" "}
                  Do you live in Delhi, Mumbai, Kolkata or Chennai?
                </Form.Label>
                <div className="d-flex gap-4">
                  <Form.Check
                    type="radio"
                    name="city"
                    value="yes"
                    label="Yes"
                    checked={value.city === "yes"}
                    onChange={handleChange}
                  />
                  <Form.Check
                    type="radio"
                    name="city"
                    value="no"
                    label="No"
                    checked={value.city === "no"}
                    onChange={handleChange}
                  />
                </div>
              </Form.Group>
            </Form>
          </Col>
          <Col className=" d-flex justify-content-center">
            <div className="p-5 border rounded border-warning shadow ">
              <p className="mb-0" style={{ color: "gray", fontSize: "1.2rem" }}>
                HRA chargeable to tax
              </p>{" "}
              <h2 className="mb-3">
                <strong>
                  {"₹ "}
                  {result.hraTaxable.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                  })}
                </strong>
              </h2>
              <div className="d-flex gap-4">
                <div>
              <p className="mb-0" style={{ color: "gray", fontSize: "1.2rem" }}>
                {value.city === "yes"
                  ? "50 % of Basic Salary"
                  : "40 % of Basic Salary"}
              </p>{" "}
              <h4 className="mb-3">
                <strong>
                  {"₹ "}
                  {result.perbsalary.toLocaleString("en-IN", { maximumFractionDigits: 2 })}
                </strong>
              </h4>
              </div>
              <div>
              <p className="mb-0" style={{ color: "gray", fontSize: "1.2rem" }}>
              HRA received
              </p>{" "}
              <h4 className="mb-3">
                <strong>
                  {"₹ "}
                  {result.hra.toLocaleString("en-IN", { maximumFractionDigits: 2 })}
                </strong>
              </h4>
              </div>
              </div>
              <p className="mb-0" style={{ color: "gray", fontSize: "1.2rem" }}>
              Excess of Rent paid over 10 % of salary
              </p>{" "}
              <h4 className="mb-3">
                <strong>
                  {"₹ "}
                  {result.excessRent.toLocaleString("en-IN", { maximumFractionDigits: 2 })}
                </strong>
              </h4>
              <p className="mb-0" style={{ color: "gray", fontSize: "1.2rem" }}>
              Amount of Exempted HRA
              </p>{" "}
              <h4 className="mb-3">
                <strong>
                  {"₹ "}
                  {result.hraExempted.toLocaleString("en-IN", { maximumFractionDigits: 2 })}
                </strong>
              </h4>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Hra;

import axios from "axios";
import React, { useContext, useRef, useState } from "react";
import config from "../../Config";
import { AuthContext } from "../Context/AuthContext";
import { toast } from "react-toastify";

const ImportPurchase = () => {
  const fileInputRef = useRef(null);
  const [oldData, setOldData] = useState(null);
  const { logout } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("Olddataupload", oldData);
      formData.forEach((value, key) => {
        if (value instanceof File) {
          console.log(`${key}:`, value.name, value.size, value.type);
        } else {
          console.log(`${key}:`, value);
        }
      });
      const res = await axios.post(`${config.BASE_URL}/csvfile`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        toast.success("Old Data Uploaded!");
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  const handleChange = (e) => {
    const { files } = e.target;
    const file = files[0];
    setOldData(file);
    console.log(file, oldData);
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Old data upload</h4>
            <h6>Upload your old data</h6>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label> Upload CSV File</label>
                    <div>
                      <input
                        type="file"
                        accept=".csv, .xls, .xlsx"
                        onChange={handleChange}
                        ref={fileInputRef}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 d-flex justify-content-end">
                  <button type="submit" className="btn btn-submit me-2">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ImportPurchase;

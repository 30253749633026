import React, { useContext, useEffect, useState } from "react";
import { ItemContext } from "../Context/ItemContext";
import { VendorContext } from "../Context/VendorContext";
import DataTable from "react-data-table-component";
import config from "../../Config";
import axios from "axios";
import Select from "react-select";

import { PurchaseContext } from "../Context/PurchaseContext";
import { AuthContext } from "../Context/AuthContext";
import { toast } from "react-toastify";
import { PartyContext } from "../Context/PartyContext";
import { StoreContext } from "../Context/StoreContext";
import { GodownContext } from "../Context/GodownContext";

const AddSales = () => {
  const { items, getItems } = useContext(ItemContext);
  const [stock, setStock] = useState([]);
  const { stores } = useContext(StoreContext);
  const { godowns } = useContext(GodownContext);
  const { parties } = useContext(PartyContext);
  const [itemList, setItemList] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const token = localStorage.getItem("token");
  const { logout } = useContext(AuthContext);
  const [selectedItem, setSelectedItem] = useState({
    itemName: "",
    quantity: 0,
    categoryId: "",
    itemId: "",
  });
  const [sale, setSale] = useState({
    partyId: "",
    items: [],
    salesDate: "",
  });

  const tableHead = [
    {
      name: "Item Name",
      selector: (row) => row.itemName,
      sortable: true,
    },
    {
      name: "Cetegory",
      selector: (row) => row.categoryId,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row, index) => (
        <>
          {/* <a onClick={() => handleEditClick(row)} className="me-3">
            <img src="/img/icons/edit.svg" alt="img" />
          </a> */}
          <a onClick={() => handleDelete(index)} className="confirm-text">
            <img src="/img/icons/delete.svg" alt="img" />
          </a>
        </>
      ),
      sortable: false,
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "quantity") {
      setSelectedItem((prevdata) => ({
        ...prevdata,
        [name]: [value],
      }));
    } else if (name === "item") {
      const valueObj = JSON.parse(value);
      console.log(valueObj);
      setSelectedItem((prevdata) => ({
        ...prevdata,
        itemName: valueObj.itemName,
        category: valueObj.category,
        itemId: valueObj._id,
      }));
    } else if (name === "party" || name === "salesDate") {
      setSale((prevdata) => ({
        ...prevdata,
        [name]: value,
      }));
    }
  };

  const handleAdd = () => {
    if (selectedItem.itemName !== "" && parseInt(selectedItem.quantity) !== 0) {
      const newItem = {
        itemName: selectedItem.itemName,
        quantity: parseInt(selectedItem.quantity),
        categoryId: selectedItem.categoryId,
        itemId: selectedItem.itemId,
      };

      const existingItemIndex = itemList.findIndex(
        (item) => item.itemId === newItem.itemId
      );

      if (existingItemIndex !== -1) {
        // If it exists, update its quantity
        const updatedItemList = itemList.map((item, index) =>
          index === existingItemIndex
            ? { ...item, quantity: item.quantity + newItem.quantity }
            : item
        );
        setItemList(updatedItemList);
      } else {
        // If it doesn't exist, add the new item
        setItemList([...itemList, newItem]);
      }
      console.log(itemList);
      setSale((prevdata) => ({
        ...prevdata,
        items: [...prevdata.items, newItem],
      }));
      setSelectedItem((prevdata) => ({
        ...prevdata,
        quantity: 0,
      }));
      setStock([
        ...stock,
        {
          itemId: newItem.itemId,
          quantity: newItem.quantity,
        },
      ]);
    } else {
      alert("Please select an item and enter a quantity.");
    }
  };
  const handleDelete = (index) => {
    const itemToDelete = itemList[index];

    // Filter out the item to delete from the itemList
    const updatedItemList = itemList.filter((_, i) => i !== index);
    const updatedStock = stock.filter((_, i) => i !== index);

    // Update totalPrice by subtracting the itemPrice of the deleted item
    setSale((prevdata) => ({
      ...prevdata,
      items: updatedItemList,
    }));

    // Update the itemList state
    setStock(updatedStock);
    setItemList(updatedItemList);
  };

  const handleSubmit = async () => {
    try {
      if (
        sale.items.length !== 0 &&
        sale.salesDate !== "" &&
        sale.partyId !== ""
      ) {
        const res = await axios.post(`${config.BASE_URL}/add-sales`, sale, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (res.status === 200 || res.status === 201) {
          toast.success("Sales Added!");
          getItems();
          setItemList([]);
          setSale((prevdata) => ({
            ...prevdata,
            partyId: "",
            salesDate: "",
            items: [],
          }));
          setSelectedItem((prevdata) => ({
            ...prevdata,
            quantity: 0,
          }));
        }
      } else {
        toast.warning("Please make sure you have entered date and Items");
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAdd(itemList._id);
    }
  };

  const partyOptions = parties.map((element) => ({
    value: element._id,
    label: element.partyName,
  }));
  const itemOptions = items.map((element) => ({
    value: JSON.stringify(element),
    label: element.itemName,
  }));

  const handleSelectChange = (selectedOption, name) => {
    if (name === "items") {
      const valueObj = JSON.parse(selectedOption.value);
      console.log(valueObj);
      setSelectedItem((prevdata) => ({
        ...prevdata,
        itemName: valueObj.itemName,
        categoryId: valueObj.category,
        itemId: valueObj._id,
      }));
    } else {
      setSale((prevdata) => ({
        ...prevdata,
        [name]: selectedOption.value,
      }));
    }
  };

  useEffect(() => {
    console.log(sale);
  }, [sale]);

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Create Sales</h4>
            <h6>Add/Update Sales</h6>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="col-lg-10  col-12">
                  <div className="form-group">
                    <label>Party</label>

                    <Select
                      options={partyOptions}
                      onChange={(selectedOption) =>
                        handleSelectChange(selectedOption, "partyId")
                      }
                    />
                    {/* <select
                      className="select"
                      onChange={handleChange}
                      name="party"
                      value={sale.party}
                    >
                      <option value={""} disabled>
                        Select Party
                      </option>
                      {parties &&
                        parties.map((element) => (
                          <option key={element._id} value={element._id}>
                            {element.partyName}
                          </option>
                        ))}
                    </select> */}
                  </div>
                </div>
                {/* <div className="col-lg-10 col-12">
                  <div className="form-group">
                    <label>Store</label>

                   

                    <select
                      className="select"
                      onChange={handleChange}
                      name="store"
                      value={sale.vendor}
                    >
                      <option value={""} disabled>
                        Select Store
                      </option>
                      {stores &&
                        stores.map((element) => (
                          <option key={element._id} value={element._id}>
                            {element.storeName}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-10 col-12">
                  <div className="form-group">
                    <label>Godown</label>



                    <select
                      className="select"
                      onChange={handleChange}
                      name="godown"
                      value={sale.vendor}
                    >
                      <option value={""} disabled>
                        Select Godown
                      </option>
                      {godowns &&
                        godowns.map((element) => (
                          <option key={element._id} value={element._id}>
                            {element.godownName}
                          </option>
                        ))}
                    </select>
                  </div>
                </div> */}
                <div className="col-lg-10 col-12">
                  <div className="form-group">
                    <label>Sales Date </label>
                    <div className="input-groupicon">
                      <input
                        type="date"
                        placeholder="DD-MM-YYYY"
                        onChange={handleChange}
                        value={sale.salesDate}
                        name="salesDate"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-10 col-12">
                  <div className="form-group">
                    <label>Item</label>

                    <Select
                      options={itemOptions}
                      onChange={(selectedOption) =>
                        handleSelectChange(selectedOption, "items")
                      }
                    />
                    {/* <select
                      className="select"
                      name="item"
                      onChange={handleChange}
                    >
                      <option value={""} disabled>
                        Select Item
                      </option>
                      {items &&
                        items.map((element) => (
                          <option
                            key={element._id}
                            value={JSON.stringify(element)}
                          >
                            {element.itemName}
                          </option>
                        ))}
                    </select> */}
                  </div>
                </div>

                <div className="col-lg-11 col-12">
                  <div className="form-group">
                    <label>Quantity</label>
                    <div className="row">
                      <div className="col-lg-10 col-sm-10 col-10">
                        <input
                          type="number"
                          name="quantity"
                          onChange={handleChange}
                          value={selectedItem.quantity}
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                      <div className="col-lg-2 col-sm-2 col-2 ps-0">
                        <div
                          onClick={() => handleAdd(itemList._id)}
                          className="add-icon"
                        >
                          <a>
                            <img src="/img/icons/plus1.svg" alt="img" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-12 overflow-auto"
                style={{ maxHeight: "55vh" }}
              >
                <DataTable columns={tableHead} data={itemList} />
              </div>
            </div>

            <div className="row d-flex justify-content-end">
              <div className="col-lg-12 d-flex justify-content-end">
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="btn btn-submit me-2"
                >
                  Submit
                </button>
                {/* <a href="salelist.html" className="btn btn-cancel">
                    Cancel
                  </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSales;

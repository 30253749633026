import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { IoArrowBackSharp } from "react-icons/io5";

const BussinessEmi = () => {
  const [value, setValue] = useState({
    year: 3,
    loanAmount: 100000,
    interest: 5,
  });
  const [result, setResult] = useState({
    invested: 0,
    total: 0,
  });
  const [visible, setVisible] = useState({
    interest:false
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "loanAmount" || name === "emi") {
      if (/^\d*$/.test(value)) {
        setValue((prevdata) => ({
          ...prevdata,
          [name]: value,
        }));
      }
    } else if (/^\d*\.?\d*$/.test(value)) {
      setValue((prevdata) => ({
        ...prevdata,
        [name]: value,
      }));
    }
  };
  const check = () => {
    
    if (Number(value.interest) > 24 || Number(value.interest) < 4 ) {
      setVisible((prevdata) => ({
        ...prevdata,
        interest: true,
      }));
    } else {
      setVisible((prevdata) => ({
        ...prevdata,
        interest: false,
      }));
    }
  };

  useEffect(() => {
    calculate();
  }, []);

  useEffect(() => {
    calculate();
  }, [value]);

  const calculate = () => {
    check();
    const rate = Number(value.interest )/ 1200;
      const time = Number(value.year) * 12;
      const rateTerm = Math.pow((1 + rate), time)
      const result = (Number(value.loanAmount) * rate * rateTerm) / (rateTerm - 1);
      setResult(result);
  };
  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="page-wrapper">
      <div className="content">
      <div className="d-flex align-items-center mb-5 gap-4">
      <IoArrowBackSharp onClick={goBack} style={{fontSize:"1.5rem",cursor:"pointer"}} />
        <h1>Bussiness Loan EMI Calculator</h1>
        </div>
        <Row className="align-items-center">
          <Col className="col-">
            <Form>
              <Form.Group className="mb-4">
                <Form.Label>Loan Amount</Form.Label>
                <Form.Control
                  type="text"
                  name="loanAmount"
                  value={value.loanAmount}
                  onChange={handleChange}
                />
                <Row className="align-items-center">
                  <Col xs="auto">
                    <Form.Label>1L</Form.Label>
                  </Col>
                  <Col>
                    <Form.Range
                      min={100000}
                      max={100000000}
                      value={value.loanAmount}
                      name="loanAmount"
                      onChange={handleChange}
                      step={100}
                    />
                  </Col>
                  <Col xs="auto">
                    <Form.Label>10CR</Form.Label>
                  </Col>
                </Row>
                {visible.loanAmount && (
                  <Form.Text className="text-danger">
                    Error: Please enter amount between 1 Lakh and 2 Crores
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Interest Rate</Form.Label>
                <Form.Control
                  type="text"
                  name="interest"
                  value={value.interest}
                  onChange={handleChange}
                />
                <Row className="align-items-center">
                  <Col xs="auto">
                    <Form.Label>4%</Form.Label>
                  </Col>
                  <Col>
                    <Form.Range
                      min={4}
                      max={24}
                      value={value.interest}
                      name="interest"
                      onChange={handleChange}
                      step={0.5}
                    />
                  </Col>
                  <Col xs="auto">
                    <Form.Label>24%</Form.Label>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Loan Tenure</Form.Label>
                <Form.Control
                  type="text"
                  name="year"
                  value={value.year}
                  onChange={handleChange}
                />
                <Row className="align-items-center">
                  <Col xs="auto">
                    <Form.Label>1 Year</Form.Label>
                  </Col>
                  <Col>
                    <Form.Range
                      min={1}
                      max={30}
                      value={value.year}
                      name="year"
                      onChange={handleChange}
                      
                    />
                  </Col>
                  <Col xs="auto">
                    <Form.Label>30 Year</Form.Label>
                  </Col>
                </Row>
                {visible.age && (
                  <Form.Text className="text-danger">
                    Error: Please enter rate between 0 % and 20 %
                  </Form.Text>
                )}
              </Form.Group>
            </Form>
          </Col>
          <Col className=" d-flex justify-content-center">
            <div className="p-5 border rounded border-warning shadow text-center">
              <p className="mb-0" style={{ color: "gray", fontSize: "1.2rem" }}>
                Total EMI Payable
              </p>{" "}
              <h2 className="mb-3">
                <strong>
                  {"₹ "}{result.toLocaleString('en-IN', { maximumFractionDigits: 2 })}
                </strong>
              </h2>
              
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BussinessEmi;

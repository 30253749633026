// Layout.js
import React from 'react'
import Navbar from '../Navbar/Navbar';
import { AuthContext } from '../Context/AuthContext';



const Layout = ({children}) => {
  return (
    <>
    <div className='main-wrapper'>
      <Navbar />
      {children}
    </div>
    </>
  );
};

export default Layout;

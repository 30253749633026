import React from "react";
import { Link } from "react-router-dom";

const CalculatorHome = () => {
  const data = [
    {
      img: "/img/Calculator/pig.avif",
      title: "PF Calculator",
      link: "/pf",
      description:
        "Calculate your Provident Fund contributions and maturity amounts effortlessly.",
    },
    {
      img: "/img/Calculator/nps.avif",
      title: "NPS Calculator",
      link: "/nps",
      description:
        "Estimate your National Pension System returns and retirement savings.",
    },
    {
      img: "/img/Calculator/salary.avif",
      title: "Salary Calculator",
      link: "/salary",
      description:
        "Break down your salary into components, deductions, and net pay.",
    },
    {
      img: "/img/Calculator/income_tax.avif",
      title: "Income Tax",
      link: "/income-tax",
      description:
        "Calculate your income tax liability based on current tax slabs.",
    },
    {
      img: "/img/Calculator/loan.avif",
      title: "Loan Tenure",
      link: "/loan-tenure",
      description: "Determine the duration needed to repay your loan amount.",
    },
    {
      img: "/img/Calculator/amount.avif",
      title: "Loan Amount",
      link: "/loan-amount",
      description: "Calculate the maximum loan amount you can qualify for.",
    },
    {
      img: "/img/Calculator/emi.avif",
      title: "EMI Calculator",
      link: "/loan-emi",
      description:
        "Estimate your monthly EMI for any loan based on principal and interest.",
    },
    {
      img: "/img/Calculator/ppf.avif",
      title: "PPF Calculator",
      link: "/ppf",
      description:
        "Calculate your Public Provident Fund maturity amount and interest earnings.",
    },
    {
      img: "/img/Calculator/bussiness.avif",
      title: "Business Loan EMI Calculator",
      link: "/bussiness-loan-emi",
      description:
        "Estimate EMIs for your business loan based on loan amount and tenure.",
    },
    {
      img: "/img/Calculator/gratuity.avif",
      title: "Gratuity Calculator",
      link: "/gratuity",
      description:
        "Calculate your gratuity amount based on years of service and salary.",
    },
    {
      img: "/img/Calculator/hra.avif",
      title: "HRA Exemption Calculator",
      link: "/hra",
      description:
        "Determine your HRA exemption based on salary, rent, and location.",
    },
    {
      img: "/img/Calculator/gst.avif",
      title: "GST Calculator",
      link: "/gst",
      description: "Calculate GST on goods and services for accurate pricing.",
    },
    {
      img: "/img/Calculator/interest.avif",
      title: "Simple Interest",
      link: "/simple-interest",
      description:
        "Compute simple interest based on principal, rate, and time period.",
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <h1 className="mb-5">Financial Calculators: All You Need To Know</h1>
        <div className="w-100 row ">
          {data.map((item) => (
            <div className="p-3 col-lg-3 col-md-6 col-12 ">
              <div className="p-3 border-light shadow-sm text-center rounded-3 " style={{backgroundColor:'white'}}>
              <Link
                to={item.link}
                className="d-flex flex-column gap-3 align-items-center"
              >
                <img
                  src={item.img}
                  alt="logo-calculator"
                  style={{ width: "120px", height: "120px" }}
                />
                <h2> {item.title} </h2>
                <p className="px-5" style={{ color: "gray" }}>
                  {item.description}
                </p>
              </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CalculatorHome;
